<div *ngIf="!item.hasSubItems()">
  <a (click)="openLink(item.route, item.externalLink)"
   class="dropdown-item"  *accessControl="{id:item.getPermission()}"
   fxLayout="row" fxLayoutAlign="space-between center" matRipple>
   <mat-icon *ngIf="item.iconPosition == 'left'" class="icon">{{ item.icon }}</mat-icon>
   <span class="name" [matBadge]="getContentCount(item.contentName)" [matBadgeHidden]="getContentCount(item.contentName) == 0" matBadgePosition="after" matBadgeColor="warn">{{ item.name | transloco }}</span>
   <mat-icon *ngIf="item.iconPosition == 'right'" class="icon">{{ item.icon }}</mat-icon>
  </a>

</div>

<a (click)="toggleDropdown()" *ngIf="item.hasSubItems()" class="dropdown-item" 
   fxLayout="row" fxLayoutAlign="space-between center" matRipple>
  <span class="name">{{ item.name | transloco}}</span>
  <span fxFlex></span>
  <mat-icon *ngIf="item.hasSubItems()" class="dropdown-indicator" [class.rotate]="isOpen(item)">expand_more</mat-icon>
  <div class="letter-icon">{{ item.generateLetterIcon() }}</div>
</a>

<div class="sub-menu" [style.height]="getSubMenuHeight()" *accessControl="{id:item.getPermission()}">
  <elastic-toolbar-navigation-dropdown-item
    class="dropdown-item-container" *ngFor="let subItem of item.subItems" [item]="subItem" [currentlyOpen]="currentlyOpen"></elastic-toolbar-navigation-dropdown-item>
</div>
