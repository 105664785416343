import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ROUTE_TRANSITION } from '../../../../app/app.animation';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'nuvolaweb-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class ResetPasswordComponent implements OnInit {

  public ngForm:FormGroup;
  public checked:boolean = true;
  
  private code:string;
  public isPreloadEnd:boolean = false;
  public success:boolean = true; 
  public isLoading:boolean;

  hideConfirm = true;
  hide = true;

  constructor(
    private fb:FormBuilder,
    private activeRoute:ActivatedRoute,
    private router:Router,
    private authenticateService:AuthenticationService,
    private _translocoService:TranslocoService,
    private snackBar:MatSnackBar,
  ) { }

  ngOnInit() {
    this.ngForm = this.fb.group({
      //password:[null, [Validators.min(8), Validators.max(16), Validators.pattern(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/)]],
      password:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
      passwordConfirm:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]]
    });
    this.code = this.activeRoute.snapshot.params.code;
    if(!this.code){
      this.router.navigate(["/"]);
    }

    this.isLoading = true;
    this.authenticateService.checkResetPassword(this.code).subscribe((resp:boolean) => {
      this.checked = resp;
      this.isLoading = false;
    });
  }

  send(){
    if(!this.ngForm.valid){
      this.ngForm.touched;
      return;
    }
    
    if(this.ngForm.controls.password.value != this.ngForm.controls.passwordConfirm.value){
      this.snackBar.open(this._translocoService.translate("RESET.passwordNotSimilar"), "X", {
        direction:"rtl",
        duration:3000,
        panelClass:"warning",
        horizontalPosition:"right",
        verticalPosition:"top",
      } as MatSnackBarConfig);
      return;
    }

    this.isLoading = true;
    this.authenticateService.resetPassword(this.code, this.ngForm.controls.password.value).subscribe((resp:boolean) => {
      if(resp){
        this.success = resp;
      }else{
        this.success = resp;
      }
      this.isLoading = false;
      this.isPreloadEnd = true;
    }, error => {
      this.isPreloadEnd = true;
      this.isLoading = false;
      this.success = false;
    })

  }

}
