import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
export class ConvertService {
    constructor() { }
    dateConvert(date, format) {
        if (date) {
            if (format) {
                return moment(date).format(format);
            }
            else {
                return moment(date).format('DD/MM/YYYY HH:mm');
            }
        }
        return '';
    }
    shortDateConvert(date) {
        if (date) {
            return moment(date).format(environment.dateFormat);
        }
        return '';
    }
    currencyConvert(value, format) {
        if (value || value === 0) {
            return value.toLocaleString('it-IT', {
                style: 'currency',
                currency: format ? format : 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '';
    }
}
ConvertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConvertService_Factory() { return new ConvertService(); }, token: ConvertService, providedIn: "root" });
