<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
    <div class="forgot mat-elevation-z12">
      <div class="forgot-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="45px">
        <img class="logo" src="assets/img/nuvola_logo_white.png">
        <h5 class="panel-title">NuvolaWeb 4.0</h5>
      </div>
      <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch">
        <p class="sessionExpired">SESSION IS EXPIRED</p>
        <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
      </div>
    </div>
  </div>
  