import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nuvolaweb-treatment-resons',
  templateUrl: './treatment-resons.component.html',
  styleUrls: ['./treatment-resons.component.scss']
})
export class TreatmentResonsComponent implements OnInit {
  @Input() backgroundColor = '#FFF';
  @Input() textColor = '#111';
  @Input() value: string;
  @Input() property: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit() {
  }

}
