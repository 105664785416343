import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'nuvolaweb-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class ChangePasswordComponent implements OnInit {

  public ngForm:FormGroup;
  public isLoading:boolean = false;

  hideOldPassword = true;
  hideNewPassword = true;
  hideNewConfirm = true;
  
  constructor(
    private _fb:FormBuilder,
    private _router:Router,
    private snackBar:MatSnackBar,
    private _translocoService:TranslocoService,
    private _authService:AuthenticationService
  ) { }

  ngOnInit() {
    if(!this._authService.emailToChangePassword){
      this._router.navigate(["/"], {replaceUrl:true});
      return;
    }
    this.ngForm = this._fb.group({
      oldPassword:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
      newPassword:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
      confirmPassword:[null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
    });
  }


  changePassword(){
    if(!this.ngForm.valid){
      this.ngForm.markAllAsTouched();
      return;
    }

    if(this.ngForm.controls.oldPassword.value == this.ngForm.controls.newPassword.value){
      this.snackBar.open(this._translocoService.translate("CHANGEPASSWORD.oldEqualsNew"), "X", {
        direction:"rtl",
        duration:3000,
        panelClass:"warning",
        horizontalPosition:"right",
        verticalPosition:"top",
      } as MatSnackBarConfig);
      return;
    }

    if(this.ngForm.controls.confirmPassword.value != this.ngForm.controls.newPassword.value){
      this.snackBar.open(this._translocoService.translate("CHANGEPASSWORD.passwordNotEquals"), "X", {
        direction:"rtl",
        duration:3000,
        panelClass:"warning",
        horizontalPosition:"right",
        verticalPosition:"top",
      } as MatSnackBarConfig);
      return;
    }

    var request = {
      "oldPassword":this.ngForm.controls.oldPassword.value,
      "newPassword":this.ngForm.controls.newPassword.value,
      "email":this._authService.emailToChangePassword
    }
    this.isLoading = true;
    this._authService.changePassword(request).subscribe(resp => {
      if(resp){
        this.snackBar.open(this._translocoService.translate("CHANGEPASSWORD.passwordChangeSuccess"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"success",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
        this._router.navigate(["/"], {replaceUrl:true});
      }
      this.isLoading = true;
    }, (error:HttpErrorResponse) => {
      this.snackBar.open(this._translocoService.translate("CHANGEPASSWORD.passwordChangeError"), "X", {
        direction:"rtl",
        duration:3000,
        panelClass:"warning",
        horizontalPosition:"right",
        verticalPosition:"top",
      } as MatSnackBarConfig);
      this.isLoading = true;
    });
  }

}
