import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { flags } from '../../../../environments/flags';
import { environment } from 'src/environments/environment';

const flagsSettings = flags;

@Component({
  selector: 'nuvolaweb-toolbar-language-selector',
  templateUrl: './toolbar-language-selector.component.html',
  styleUrls: ['./toolbar-language-selector.component.scss']
})
export class ToolbarLanguageSelectorComponent implements OnInit {
 
  isOpen: boolean;
  isUsaVersion: boolean;

  public availableLanguages:any[]|{id:string, label:string}[]=[];
  public currentLanguage:{id:string, label:string, flagPath?:string};
  constructor(
    private translocoService:TranslocoService,
    private cd:ChangeDetectorRef
  ) { 
    this.isUsaVersion = environment.isUsaVersion;
    console.log('isUsaVersion', this.isUsaVersion);
  }

  ngOnInit() {
    this.availableLanguages = this.translocoService.getAvailableLangs();
    this.availableLanguages.forEach(x=> {
      var flag = flagsSettings.find(t=> t.id == x.id);
      if(flag){
        x.flagPath = flag.flagPath;
      }
    });
    this.reloadCurrentLanguage();
    this.cd.detectChanges();
  }

  private reloadCurrentLanguage() {
    this.currentLanguage = this.availableLanguages.find(x=> x.id == this.translocoService.getActiveLang());
    if (this.isUsaVersion)
      this.currentLanguage.flagPath = "assets/img/flags/us-flag.png";
    this.cd.detectChanges();
  }

  onLanguageSelect(lang:string){
    this.translocoService.setActiveLang(lang);
    localStorage.setItem("nuvolaLanguage", lang);
    this.reloadCurrentLanguage();
    this.toggleDropdown();
  }

  onClickOutside() {
    this.isOpen = false;
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

}
