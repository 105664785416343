import { HttpClient } from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { httpHeaders } from '../../../../environments/httpHeaders';

const apiUrl = environment.apiUrl;
const headers = httpHeaders.headers;

@Injectable({
  providedIn: 'root'
})
export class TreatmentAdditionalFunctionalService {


  private treatmentPlan: any;
  public areValueWasChanged = true;
  public isPanelCollapsed: boolean;
  public treatmentId: any;

  constructor(
    private _http: HttpClient
  ) {
  }

  public isLowerAbleToShow = true;
  public isUpperAbleToShow = true;
  private isTabContentDisabledValue: boolean;

  private isTabsContentDisabled$ = new Subject<boolean>();

  public isTabsContentDisabled = this.isTabsContentDisabled$.asObservable();

  public archesToHandleChangeHandler(value: string) {
    if (value === 'N' || value === 'B') {
      this.isUpperAbleToShow = true;
      this.isLowerAbleToShow = true;
      return;
    }

    if (value === 'L') {
      this.isLowerAbleToShow = true;
      this.isUpperAbleToShow = false;
    } else {
      this.isLowerAbleToShow = false;
      this.isUpperAbleToShow = true;
    }
  }

  public async initService(treatmentId) {
    this.treatmentId = treatmentId;
    this.treatmentPlan = await this._http.get(`${apiUrl}/api/treatmentplan/${treatmentId}`, { headers: headers }).toPromise();
    this.archesToHandleChangeHandler(this.treatmentPlan.treatmentPlan.archesHandled);
  }

  public getPlanField(fieldName) {
    return this.treatmentPlan ? this.treatmentPlan[fieldName] : null;
  }

  public setpAlignersOnPhase(upperA: number, lowerA: number) {
    if (upperA > 0) {
      this.treatmentPlan.phase.alignersUpperArch = upperA > 12 ? 12 : upperA;
    }
    if (lowerA > 0) {
      this.treatmentPlan.phase.alignersLowerArch = lowerA > 12 ? 12 : lowerA;
    }
  }

  public setPlanField(fieldName, field) {
    this.treatmentPlan[fieldName] = field;
  }

  public updateShowPlanDelay(newvalue) {
    this.treatmentPlan.treatmentPlan.showPlanDelay = newvalue;
  }

  public updateShowPlanComplex(newvalue) {
    this.treatmentPlan.treatmentPlan.showPlanComplex = newvalue;
  }

  public updateShowPlanCategories(newvalue) {
    this.treatmentPlan.treatmentPlan.showPlanCategories = newvalue;
  }

  public updateShowPlanAttachmentNotes(newvalue) {
    this.treatmentPlan.treatmentPlan.attachmentNotes = newvalue;
  }

  public getPlanAttachmentNotes() {
    return this.treatmentPlan.treatmentPlan.attachmentNotes;
  }

  // public updatePdfCreated(newvalue) {
  //   this.treatmentPlan.pdfCreated = newvalue;
  // }

  public getTreatmentPlan() {
    return this.treatmentPlan;
  }

  public changePanelContentStatus(value: boolean) {
    this.isTabContentDisabledValue = value;
    this.isTabsContentDisabled$.next(value);
  }

  public getIsTabContentDisabledValue() {
    return this.isTabContentDisabledValue;
  }

  public phaseValueChanged(arg0: boolean) {
    this.areValueWasChanged = arg0;
  }
}
