<div class="autocomplete group card alternative no-border mat-elevation-z2" fxLayout="column" fxLayoutGap="24px">
  <div class="group-title">Material Tooltip</div>
  <div class="divider reverse"></div>
  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start">
    <div fxFlex.gt-md>
      <div fxLayout="column">
        <div class="section-title">Tooltip</div>
        <div class="section-tag">&lt;matTooltip&gt;</div>
        <p>Tooltips offer space for additional information on e.g. an icon.</p>
        <div class="section-info">Example:</div>
        <code vrHighlight class="html">&lt;button mat-button matTooltip="I'm a Tooltip!"&gt;<br/>  Button<br/>&lt;/button&gt;</code>
      </div>
    </div>
    <mat-tab-group class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%" fxFlexOffset.gt-md="24px">
      <mat-tab label="PREVIEW">
        <div class="preview" fxLayout="row" fxLayoutAlign="center center">
          <mat-card fxFlex="90%" fxFlex.gt-sm="80%">
            <mat-card-content fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="space-around center">
              <button mat-icon-button matTooltip="Favorite this"><mat-icon>favorite</mat-icon></button>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="HTML">
        <code [innerHTML]="tooltipHTML" vrHighlight class="html"></code>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
