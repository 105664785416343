import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  public dateConvert(date, format?) {
    if (date) {
      if (format) {
        return moment(date).format(format);
      } else {
        return moment(date).format('DD/MM/YYYY HH:mm');
      }
    }
    return '';
  }

  public shortDateConvert(date) {
    if (date) {
      return moment(date).format(environment.dateFormat);
    }
    return '';
  }

  public currencyConvert(value: number, format?) {
    if (value || value === 0) {
      return value.toLocaleString('it-IT', {
        style: 'currency',
        currency: format ? format : 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return '';
  }
}
