import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericAutocompleteInputComponent } from './generic-autocomplete-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatInputModule, MatOptionModule } from '@angular/material';
import { TranslocoModule } from '@ngneat/transloco';



@NgModule({
  declarations: [GenericAutocompleteInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    TranslocoModule
  ],
  exports:[
    GenericAutocompleteInputComponent
  ]
})
export class GenericAutocompleteInputModule { }
