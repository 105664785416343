<mat-sidenav-container class="layout-container" [ngClass]="cardElevation$ | async">

  <mat-sidenav class="settings-sidenav"
              (openedStart)="openSettings()" (closedStart)="closeSettings()"
              [opened]="settingsOpen$ | async" mode="over" position="end" #options>
    <elastic-settings></elastic-settings>
  </mat-sidenav>

  <div class="layout alpha boxed" fxLayout="column">
    <!-- Layout Gamma -->
    <elastic-toolbar-gamma
                      [quickpanelOpen]="quickpanelOpen$ | async"
                      (toggledSidenav)="toggleSidenav()" (toggledQuickpanel)="toggleQuickpanel()"></elastic-toolbar-gamma>
    <!-- /Layout: Gamma -->
    <elastic-toolbar-navigation></elastic-toolbar-navigation>


    <mat-sidenav-container fxFlex>
      <mat-sidenav class="quickpanel-container"
                  (openedStart)="openSidenav()" (closedStart)="closeSidenav()"
                  [opened]="sidenavOpen$ | async" [position]="sidenavAlign$ | async"
                  [mode]="sidenavMode$ | async" [disableClose]="sidenavDisableClose$ | async"
                  [vrSidenavCollapse]="sidenavCollapsed$ | async">

        <elastic-sidenav [layout]="layout" [collapsed]="sidenavCollapsed$ | async"
                    (toggledSidenavCollapse)="toggleSidenavCollapse()"></elastic-sidenav>

      </mat-sidenav>

      <div class="toolbar-container" fxLayout="column">
        <div class="content-container" fxFlex>
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-container>

  </div>
  
</mat-sidenav-container>

