<div class="content" fxFlex fxLayoutGap="25px" fxLayoutAlign="center" fxLayout="column">

    <div *ngIf="!hideTitle">
        <h5>{{'patient.ADDITIONAL.lowerArchLabel' | transloco}}</h5>
    </div>

    <div style="display: flex; flex-direction: column;">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin-left: 0px;">
            <div style="width: 80px;height: 73px;position: relative;">
                <div style="position: absolute;bottom: 0;left: 0;">{{'patient.ADDITIONAL.upperArchBeforeOfLabel' | transloco}}</div>
            </div>
            <div *ngFor="let tooth of lowerTeeth" fxLayout='column' class="tooth-item" [style.backgroundImage]="'url('+tooth.src+')'"
            style="height: 73px; width: 50px; background-size: auto 82%; background-position: center top; background-repeat: no-repeat;">
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 30px 0 0;">
            <form [formGroup]="lowerArch_1Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            <div *ngFor="let tooth of lowerTeeth.slice(0, lowerTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="lowerArch_1Form" style="width: 50px;">
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="lowerArch_1Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 20px 0 0;">
            <form [formGroup]="lowerArch_2Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            <div *ngFor="let tooth of lowerTeeth.slice(0, lowerTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="lowerArch_2Form" style="width: 50px;">
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="lowerArch_2Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 20px 0 0;">
            <form [formGroup]="lowerArch_3Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            <div *ngFor="let tooth of lowerTeeth.slice(0, lowerTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="lowerArch_3Form" style="width: 50px;">
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="lowerArch_3Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
    
    </div>    
</div>