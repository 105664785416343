import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../../modules/security/models/user.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
export class ToolbarUserButtonComponent {
    constructor(usersService, userService, matDialog, cd, router, snackBar, _translocoService, route) {
        this.usersService = usersService;
        this.userService = userService;
        this.matDialog = matDialog;
        this.cd = cd;
        this.router = router;
        this.snackBar = snackBar;
        this._translocoService = _translocoService;
        this.route = route;
        this._sub = new Subscription();
        this.showMyUsersMenuItem = false;
    }
    ngOnDestroy() {
        this._sub.unsubscribe();
    }
    ngOnInit() {
        this.currentUser = this.userService.readUserFromLocalStorage();
        this.showMyUsersMenuItem = this.currentUser.role == 'US' &&
            this.currentUser.userGroup != 'AD' &&
            this.currentUser.userGroup != 'PJ' &&
            this.currentUser.userGroup != 'ST';
        this.alignersPerDay = new FormControl();
        this.clinicPreferencesInput = new FormControl();
        this._sub = this.alignersPerDay.valueChanges.pipe(debounceTime(25)).subscribe(value => {
            value = value.toString().replace(/[^0-9]/g, "");
            this.alignersPerDay.setValue(value);
        });
        this.updateUserData();
    }
    ngAfterViewInit() {
    }
    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
    onClickOutside() {
        this.isOpen = false;
    }
    openConfirmModal() {
        this.dialogRef = this.matDialog.open(LogOutConfirmModal, {
            closeOnNavigation: true
        });
    }
    openEditCreateModeal(templateRef) {
        // this.edtiCreateModal = this.matDialog.open(templateRef, {
        //   closeOnNavigation:true, panelClass:"lg-modal"
        // });
        this.route.navigate(['/myprofile']);
    }
    openEditCreatePhotoModeal(templateRef) {
        this.edtiCreatePhotoModal = this.matDialog.open(templateRef, {
            closeOnNavigation: true, panelClass: "lg-modal"
        });
    }
    openEditClinicalPreferences(templateRef) {
        //get clinical Preferences for current Doctor
        this.isLoading = true;
        this.clinicPreferencesInput.setValue('');
        this.usersService.getClinicPreferences(this.currentUser.idAdmin).subscribe((resp) => {
            this.isLoading = false;
            this.clinicPreferencesInput.setValue(resp.clinicPreferences);
            this.clinicalPreferences = this.matDialog.open(templateRef, {
                closeOnNavigation: true, panelClass: "lg-modal", width: '600px', height: ''
            });
        }, error => {
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        });
    }
    openSettingsModal(templateRef) {
        this.isLoading = true;
        this.usersService.getSettings().subscribe((resp) => {
            this.alignersPerDay.setValue(resp.maxAlignersPerDay);
            this.isLoading = false;
        });
        this.settingsModal = this.matDialog.open(templateRef, {
            closeOnNavigation: true, panelClass: "sd-modal"
        });
    }
    closeSettings() {
        this.settingsModal.close();
    }
    closeClinicalPreferences() {
        this.clinicalPreferences.close();
    }
    saveClinicalPreferences() {
        let request = {
            "clinicPreferences": this.clinicPreferencesInput.value,
            "idDoctor": this.currentUser.idAdmin
        };
        this.isLoading = true;
        this.usersService.saveClinicPreferences(request).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate("toolbar.updateSuccessMessage"), "X", {
                duration: 3000,
                panelClass: "success",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.closeClinicalPreferences();
        }, error => {
            this.isLoading = false;
            this.closeClinicalPreferences();
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        });
    }
    saveDays() {
        let request = {
            "maxAlignersPerDay": +this.alignersPerDay.value
        };
        this.isLoading = true;
        this.usersService.saveSettings(request).subscribe(resp => {
            this.isLoading = false;
            this.snackBar.open(this._translocoService.translate("toolbar.updateSuccessMessage"), "X", {
                direction: "rtl",
                duration: 3000,
                panelClass: "success",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.closeSettings();
        }, error => {
            this.isLoading = false;
            this.closeSettings();
            this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
                direction: "rtl",
                duration: 3000,
                panelClass: "warning",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
        });
    }
    createUpdateHandler() {
        this.edtiCreateModal.close();
    }
    createUpdatePhotoHandler(value) {
        if (value.closeModal) {
            this.edtiCreatePhotoModal.close();
        }
        if (value.updated) {
            this.updateUserData(true);
        }
        this.updateUserData();
    }
    updateUserData(updateStorage = false) {
        this.usersService.getUserById(this.userService.readUserFromLocalStorage().idAdmin).subscribe((resp) => {
            this.currentUser = new User(resp);
            if (updateStorage) {
                this.userService.setUserToLocalStorage(this.currentUser);
            }
            this.cd.detectChanges();
        });
    }
    redirectTo(link, params) {
        if (params) {
            this.router.navigate([link], { queryParams: params });
            return;
        }
        this.router.navigate([link]);
    }
}
export class LogOutConfirmModal {
    constructor(dialogRef, authService, router) {
        this.dialogRef = dialogRef;
        this.authService = authService;
        this.router = router;
    }
    logOut() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.authService.logOutUser();
            this.dialogRef.close();
            this.router.navigate(["/"]);
        });
    }
}
