<div class="card group alternative no-border mat-elevation-z2" fxLayout="column" fxLayoutGap="24px">
  <div class="group-title">Material Card</div>
  <div class="divider reverse"></div>
  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start" fxLayoutGap="16px" fxLayoutGap.gt-md="24px">
    <div fxFlex.gt-md>
      <div fxLayout="column">
        <div class="section-title">Image + Action Card</div>
        <div class="section-tag">&lt;mat-card&gt;</div>
        <p>Cards are containers for text, photos and actions. They are intented to provide information on a single subject.</p>
        <div class="section-info">Example:</div>
        <code vrHighlight class="html">&lt;mat-card&gt;<br/> &lt;mat-card-title&gt;<br/>  Card Title<br/> &lt;/mat-card-title&gt;<br/> &lt;mat-card-content&gt;<br/>  Card Content<br/> &lt;/mat-card-content&gt;<br/> &lt;mat-card-actions&gt;<br/>  &lt;button&gt;Click Me!&lt;/button&gt;<br/> &lt;/mat-card-actions&gt;<br/>&lt;/mat-card&gt;</code>
      </div>
    </div>
    <mat-tab-group class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%">
      <mat-tab label="PREVIEW">
        <div class="preview" fxLayout="row" fxLayoutAlign="center center">
          <mat-card fxFlex="80%">
            <mat-card-header>
              <img mat-card-avatar src="assets/img/demo/avatars/3.png">
              <mat-card-subtitle>
                Yesterday
              </mat-card-subtitle>
              <mat-card-title>Gerald Morris</mat-card-title>
            </mat-card-header>
            <img mat-card-image src="assets/img/demo/backgrounds/pattern-1.jpg">
            <mat-card-content>
              <p>Piqued favour stairs it enable exeter as seeing. Remainder met improving but engrossed sincerity age. Better but length gay denied abroad are. Attachment astonished to on appearance imprudence so collecting in excellence. Tiled way blind lived whose new. The for fully had she there leave merit enjoy forth. </p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
              <div fxLayout="row">
                <button mat-icon-button>
                  <mat-icon>share</mat-icon>
                </button>
                <button mat-icon-button>
                  <mat-icon>favorite</mat-icon>
                </button>
                <span fxFlex></span>
                <button mat-button>
                  More Info
                </button>
                <button mat-button>
                  Save as
                </button>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="HTML">
        <code [innerHTML]="card1HTML" vrHighlight class="html"></code>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="divider reverse"></div>

  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start" fxLayoutGap="16px" fxLayoutGap.gt-md="24px">
    <div fxFlex.gt-md fxLayout="column">
      <div class="section-title">Standard Card with Action</div>
      <mat-tab-group class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%">
        <mat-tab label="PREVIEW">
          <div class="preview" fxLayout="row" fxLayoutAlign="center center">
            <mat-card fxFlex="80%">
              <mat-card-title>Standard Card with Actions</mat-card-title>
              <mat-card-subtitle>Subtitle</mat-card-subtitle>
              <mat-card-content>
                <p>Old there any widow law rooms. Agreed but expect repair she nay sir silent person. Direction
                  can dependent one bed situation attempted. His she are man their spite avoid. Her pretended
                  fulfilled extremely education yet. Satisfied did one admitting incommode tolerably how are. </p>
              </mat-card-content>
              <mat-card-actions align="end">
                <button mat-button>Cancel</button>
                <button color="primary" mat-raised-button>Action</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab label="HTML">
          <code [innerHTML]="card2HTML" vrHighlight class="html"></code>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div fxFlex.gt-md fxLayout="column">
      <div class="section-title">Standard Card</div>
      <div class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%">
        <mat-tab-group>
          <mat-tab label="PREVIEW">
            <div class="preview" fxLayout="row" fxLayoutAlign="center center">
              <mat-card fxFlex="80%">
                <mat-card-title>Standard Card</mat-card-title>
                <mat-card-subtitle>Subtitle</mat-card-subtitle>
                <mat-card-content>
                  <p>Do play they miss give so up. Words to up style of since world. We leaf to snug on no need. Way
                    own uncommonly travelling now acceptance bed compliment solicitude. Dissimilar admiration so
                    terminated no in contrasted it. Advantages entreaties mr he apartments do. Limits far yet turned
                    highly repair parish talked six. Draw fond rank form nor the day eat. </p>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-tab>
          <mat-tab label="HTML">
            <code [innerHTML]="card3HTML" vrHighlight class="html"></code>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
