<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
    <div class="forgot mat-elevation-z12">
      <div class="forgot-header" fxLayout="row" fxLayoutAlign="center center">
        <!-- <img class="logo" src="assets/img/logo2x.png"> -->
        <h4 class="panel-title">Nuvola 4.0</h4>
      </div>
      <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="isLoading">
        <mat-progress-spinner
        class="margin"
        [mode]="'indeterminate'"
        [color]="'accent'"
        [value]="10"
        >
        </mat-progress-spinner>
      </div>
      <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!isLoading">
        <p class="sessionExpired" *ngIf="!token">{{'REGISTRATION.confirmationEmailLabel' | transloco}}</p>
        <p class="sessionConfirmed" *ngIf="token && isSuccess">{{'REGISTRATION.confirmationEmailLabelSuccess' | transloco}}</p>
        <p class="sessionConfirmedSmall" *ngIf="token && isSuccess">{{'REGISTRATION.confirmationEmailLabelSuccess2' | transloco}}</p>
        <p class="sessionConfirmedSmall" *ngIf="token && isSuccess">{{'REGISTRATION.confirmationEmailLabelSuccess3' | transloco}}</p>
        <p class="sessionExpired" *ngIf="token && !isSuccess">{{'REGISTRATION.confirmationEmailLabelAlready' | transloco}}</p>
        <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
      </div>
    </div>
  </div>
  