import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionExpiredComponent } from './session-expired.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from '../../../core/toolbar/toolbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';



@NgModule({
  declarations: [SessionExpiredComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    ToolbarModule,
    MatIconModule
  ],
  exports:[SessionExpiredComponent]
})
export class SessionExpiredModule { }
