import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class UserService {
    constructor() {
        this.user = new BehaviorSubject(null);
        this.readUserFromLocalStorage();
    }
    readUserFromLocalStorage() {
        const user = JSON.parse(localStorage.getItem('nuvolaUser') || null);
        return user;
    }
    setUserToLocalStorage(user) {
        const token = localStorage.getItem('nuvola_session_token');
        const refresh = localStorage.getItem('nuvola_refresh_token');
        user.accessToken = token;
        user.refreshToken = refresh;
        localStorage.setItem('nuvolaUser', JSON.stringify(user));
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
