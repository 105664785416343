import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
export class LoginComponent {
    constructor(router, authService, fb, snackBar, _translocoService, _contentService, _userService) {
        this.router = router;
        this.authService = authService;
        this.fb = fb;
        this.snackBar = snackBar;
        this._translocoService = _translocoService;
        this._contentService = _contentService;
        this._userService = _userService;
        this.hide = true;
    }
    ngOnInit() {
        this.authService.emailToChangePassword = null;
        this.loginForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, Validators.required],
            remember: [null]
        });
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["/dashboard"]);
        }
        else {
            hideTawkChat();
            this.authService.logOutUser();
        }
    }
    formSubmit() {
        this.isLoading = true;
        if (this.loginForm.valid) {
            this.authService.loginUser(this.loginForm.controls.email.value, this.loginForm.controls.password.value, this.loginForm.controls.remember.value).subscribe((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.authService.isUserInRole(["DO", "AG", "DI", "SA", "EX"])) {
                    let result = yield this._contentService.getNewsContent(false).toPromise();
                    this._contentService.setContentHolderCountValue("news", result.length);
                }
                if (result) {
                    if (result.profileComplete)
                        this.router.navigate(['/dashboard']);
                    else
                        this.router.navigate(['/myprofile']);
                }
            }), (error) => {
                if (error.status == 403) {
                    this.authService.emailToChangePassword = this.loginForm.controls.email.value;
                    this.snackBar.open(this._translocoService.translate("LOGIN.passwordExpired"), "X", {
                        direction: "rtl",
                        duration: 3000,
                        panelClass: "warning",
                        horizontalPosition: "right",
                        verticalPosition: "top",
                    });
                    this.router.navigate(['/change-password']);
                    return;
                }
                this.snackBar.open(error.error.message, "X", {
                    direction: "rtl",
                    duration: 3000,
                    panelClass: "warning",
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
                this.isLoading = false;
            });
        }
        else {
            this.isLoading = false;
        }
        return;
    }
}
