import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { PatientService } from '../../new-patient/patient.service';
import { environment } from '../../../../environments/environment';
import { User } from 'src/app/modules/security/models/user.model';
import { UserService } from 'src/app/modules/security/services/user.service';
import { TreatmentsService } from '../../treatments/treatments.service';
import { ChatService } from '../../new-patient/treatment-additional-functional/treatment-chat/chat.service';
import { TreatmentAdditionalFunctionalService } from '../../new-patient/treatment-additional-functional/treatment-additional-functional.service';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

declare function ResizeiFrame(): any;    //js function to show/hide right panel
declare function renderWebViewer(): any;    //js function to show webviewer iframe

@Component({
  selector: 'nuvolaweb-webviewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class WebviewerComponent implements OnInit {
  @ViewChild('imagePreview', { static: true }) imagePreview: MatDialogRef<any>;
  @ViewChild('chatModal', { static: true }) chatModal: MatDialogRef<any>;
  @ViewChild('confirmModal', { static: true }) confirmModal: MatDialogRef<any>;
  @ViewChild('confirmSendToNuvolaModal', { static: true }) confirmSendToNuvolaModal: MatDialogRef<any>;

  currentUser: User;
  public treatmentId: any;
  imagesCollection = [];
  @Input() selectedTreatment: any;
  storageBaseUrl: string = environment.storageBaseUrl;
  selectedModal: any;
  modalMessage: any
  sendToNuvolaDoctorMessage: string;
  isLoading: boolean;

  showImagePreviewPanel: boolean = false;
  showChatPanel: boolean = false;
  showIPRPanel: boolean = false;

  //IPR variables
  public isUpperAbleToShow: boolean = false;
  public isLowerAbleToShow: boolean = false;
  public showIPR_Upper: boolean = false;
  public showIPR_Lower: boolean = false;
  public showHeaderBar: boolean = false;

  iprModel: any;
  iprLoaded: boolean = false;
  archesHandled: string = 'B';
  private isTabsContentDisabled$ = new Subject<boolean>();
  public isTabsContentDisabled = this.isTabsContentDisabled$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _activeRoute: ActivatedRoute,
    private _translocoService: TranslocoService,
    private _patientService: PatientService,
    private snackBar: MatSnackBar,
    private modalService: MatDialog,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private treatmentService: TreatmentsService,
    private chatService: ChatService,
    private treatmentAdditionalFunctionalService: TreatmentAdditionalFunctionalService
  ) { }

  ngOnInit() {

    this.currentUser = this.userService.readUserFromLocalStorage();

    this._activeRoute.queryParams.subscribe(param => {
      this.treatmentId = param['t'];
      if (this.treatmentId != undefined) {
        console.log('idTreatment', this.treatmentId);

        this._patientService.getTreatment(this.treatmentId).subscribe(async (resp: any) => {
          this.showHeaderBar = true;
          this.selectedTreatment = resp;
          renderWebViewer();
        }
          , error => {
            //user is not logged in - hide buttons on header
            this.showHeaderBar = false;
            this.selectedTreatment = null;
            renderWebViewer();
          });
      }
      else {
        // this.snackBar.open("You are not logged-in", "X", {
        //   duration: 3000,
        //   panelClass: "warning",
        //   horizontalPosition: "right",
        //   verticalPosition: "top",
        // } as MatSnackBarConfig);
        this.showHeaderBar = false;
        this.selectedTreatment = null;
        renderWebViewer();
      }
    });

    // console.log("location", window.location.search);
    // var iframe = document.createElement('iframe');
    // iframe.setAttribute('name', 'OnyxCephWebGL');
    // iframe.setAttribute('style', 'position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:1;');
    // //iframe.setAttribute('src', '//onyx.orthorobot.com/webviewer/main.html' + decodeURIComponent(window.location.search)); // change this to any "?mlink=abc.iiwgl&p=xxx&..." link from OnyxCeph
    // iframe.setAttribute('src', '//onyx.orthorobot.com/webviewer2/main.html' + decodeURIComponent(window.location.search)); // change this to any "?mlink=abc.iiwgl&p=xxx&..." link from OnyxCeph
    // document.body.appendChild(iframe);
  }

  async copyToClipboard(copyValue) {
    let url = this.document.location.href;
    await navigator.clipboard.writeText(url);
    this.snackBar.open(this._translocoService.translate("patient.ADDITIONAL.copyWebViewLinkToClipboardConfirm"), "X", {
      duration: 3000,
      panelClass: "success",
      horizontalPosition: "right",
      verticalPosition: "top",
    } as MatSnackBarConfig);
  }

  onChangeIPRItem(iprItem) {
    if (iprItem == 'U') {
      //upper
      this.showIPR_Upper = true;
      this.showIPR_Lower = false;
    } else if (iprItem == 'L') {
      //lower
      this.showIPR_Lower = true;
      this.showIPR_Upper = false;
    }
    this.cd.detectChanges();
  }

  openIPRModal() {

    if (this.iprLoaded) {
      ResizeiFrame();
      return;
    }

    this.treatmentService.getTreatmentPlanById(this.treatmentId).subscribe((treatmentPlan: any) => {
      //Load IPR info
      this.archesHandled = treatmentPlan.treatmentPlan.archesHandled;
      this.iprModel = treatmentPlan.ipr;
      this.isLowerAbleToShow = this.treatmentAdditionalFunctionalService.isLowerAbleToShow;
      this.isUpperAbleToShow = this.treatmentAdditionalFunctionalService.isUpperAbleToShow;
      if (this.isUpperAbleToShow)
        this.showIPR_Upper = true;
      else if (this.isLowerAbleToShow)
        this.showIPR_Lower = true;

      this.isTabsContentDisabled$.next(true);

      if (this.selectedTreatment && this.selectedTreatment.archesHandled == 'L') {
        this.onChangeIPRItem('L');
      }

      this.cd.detectChanges();

      ResizeiFrame();
      this.iprLoaded = true;
    });

    // this.snackBar.open("I'm sorry, this Feature is not ready yet!", "X", {
    //   duration: 3000,
    //   panelClass: "warning",
    //   horizontalPosition: "right",
    //   verticalPosition: "top",
    // } as MatSnackBarConfig);
    //this.showIPRPanel=!this.showIPRPanel;
    //ResizeiFrame();
  }

  async openPDT() {
    //download phase_valuestable_pdf
    this.treatmentService.getTreatmentPlanById(this.treatmentId).subscribe((treatmentPlan: any) => {
      console.log('treatmentPlan', treatmentPlan.phase.valuesTablePDF);
      if (treatmentPlan.phase.valuesTablePDF) {
        var downloadURL = this.storageBaseUrl + '/t-' + this.treatmentId + '/' + treatmentPlan.phase.valuesTablePDF;
        var link = document.createElement('a');
        link.href = downloadURL;
        link.setAttribute('target', '_blank');
        link.click();
      }
      else {
        this.snackBar.open(this._translocoService.translate("COMMON.errorDownloadFileMessage"), "X", {
          duration: 3000,
          panelClass: "warning",
          horizontalPosition: "right",
          verticalPosition: "top",
        } as MatSnackBarConfig);
      }
    });
  }

  openChatModal(template) {
    //this.chatModal = this.modalService.open(template, { panelClass: "lg-modal-chat", closeOnNavigation: false });
    this.showChatPanel = !this.showChatPanel;
  }

  closeChatModal() {
    this.chatModal.close();
  }

  closeImagesPreviewModal() {
    this.imagePreview.close();
  }

  openImagePreviewModal(imagePreview) {

    if (this.showImagePreviewPanel) {
      this.showImagePreviewPanel = false;
      return;
    }

    //prepare image preview
    this.imagesCollection = [];
    if (this.selectedTreatment) {
      if (this.selectedTreatment.imageCollage.match(/.(jpg|jpeg|png|gif)$/i)) {
        this.addImageToCollection(this.selectedTreatment.imageCollage);
      }
      this.addImageToCollection(this.selectedTreatment.imageUA);
      this.addImageToCollection(this.selectedTreatment.imageBA);
      this.addImageToCollection(this.selectedTreatment.imageLO);
      this.addImageToCollection(this.selectedTreatment.imageFO);
      this.addImageToCollection(this.selectedTreatment.imageRO);
      this.addImageToCollection(this.selectedTreatment.imageLS);
      this.addImageToCollection(this.selectedTreatment.imageFS);
      this.addImageToCollection(this.selectedTreatment.imageRS);
      this.addImageToCollection(this.selectedTreatment.imageOrtho);
      this.addImageToCollection(this.selectedTreatment.imageTelecranio);
      this.addImageToCollection(this.selectedTreatment.imageOther);
    }
    if (this.imagesCollection.length > 0)
      //this.imagePreview = this.modalService.open(imagePreview, { panelClass: "lg-modal-imagepreview", closeOnNavigation: false });
      //ResizeiFrame();
      this.showImagePreviewPanel = true;
    else {
      this.snackBar.open(this._translocoService.translate("patient.GENERAL_INFO.imagePreviewNotFound"), "X", {
        duration: 3000,
        panelClass: "warning",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    }
  }

  addImageToCollection(imageName: string) {
    if (imageName != '') {
      this.imagesCollection.push({ path: this.storageBaseUrl + '/t-' + this.treatmentId + '/' + imageName });
    }
  }

  openConfirmModal(type, modal) {
    this.selectedModal = type;

    switch (type) {
      case "sendNuvola":
        this.modalMessage = "patient.ADDITIONAL.sendToNuvolaMessage"
        this.sendToNuvolaDoctorMessage = '';
        this.confirmSendToNuvolaModal = this.modalService.open(modal, { closeOnNavigation: true, width: '600px', height: '250px' });
        break;
      case "reject":
        this.modalMessage = "patient.ADDITIONAL.rejectMessage"
        this.confirmModal = this.modalService.open(modal, { closeOnNavigation: true });
        break;
      case "approve":
        this.modalMessage = "patient.ADDITIONAL.approveMessage"
        this.confirmModal = this.modalService.open(modal, { closeOnNavigation: true });
        break;
      default:
        return;
    }
  }

  closeConfirmModal() {
    if (this.selectedModal != 'sendNuvola')
      this.confirmModal.close();
  }

  closeconfirmSendToNuvolaModal() {
    if (this.selectedModal == 'sendNuvola')
      this.confirmSendToNuvolaModal.close();
  }

  confirm() {
    this.isLoading = true;
    this.closeConfirmModal();
    this.closeconfirmSendToNuvolaModal();

    switch (this.selectedModal) {
      case "sendNuvola":
        this.treatmentService.changeStatus(null, null, "inproject",
          {
            idTreatment: + this.treatmentId
          }).subscribe(resp => {

            const confirmMsg = this.selectedModal == 'sendNuvola' ? "patient.ADDITIONAL.sendToNuvolaMessageConfirm" : "patient.ADDITIONAL.recoverMessageConfirm";

            this.selectedTreatment.status = 'INPROJECT';
            this.isLoading = false;

            this.snackBar.open(this._translocoService.translate(confirmMsg), "X", {
              duration: 3000,
              panelClass: "success",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
          });
        break;
      case "reject":
        this.treatmentService.changeStatus(null, null, "rejected",
          {
            idTreatment: + this.treatmentId,
            reason: ''
          }).subscribe(resp => {

            this.selectedTreatment.status = 'REJECTED';
            this.isLoading = false;

            this.snackBar.open(this._translocoService.translate("patient.ADDITIONAL.rejectedTreatmentMessageConfirm"), "X", {
              duration: 3000,
              panelClass: "success",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
          });
        break;
      case "approve":
        this.treatmentService.changeStatus(null, null, "approved",
          {
            idTreatment: + this.treatmentId
          }).subscribe(resp => {

            this.selectedTreatment.status = 'APPROVED';
            this.isLoading = false;

            this.snackBar.open(this._translocoService.translate("patient.ADDITIONAL.approveTreatmentPlanMessageConfirm"), "X", {
              duration: 3000,
              panelClass: "success",
              horizontalPosition: "right",
              verticalPosition: "top",
            } as MatSnackBarConfig);
          });
        break;
      default:
        return;
    }
  }

  confirmSendToNuvola() {
    console.log('this.selectedModal', this.selectedModal);
    //create a new chat message as doctor if a reason is added
    if (this.sendToNuvolaDoctorMessage != undefined && this.sendToNuvolaDoctorMessage != '') {
      //save a new chat message
      this.chatService.sendMessage(Number(this.treatmentId), this.sendToNuvolaDoctorMessage, 'T').subscribe(resp => {
        this.confirm();
      });
    } else {
      this.confirm();
    }
  }

}
