import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { Address, AddressTypes } from '../../../modules/security/models/user.model';
import { environment } from 'src/environments/environment';
export class DoctorAddressGridComponent {
    constructor(translocoService, modalServide, userService, treatmentsService, snackBar) {
        this.translocoService = translocoService;
        this.modalServide = modalServide;
        this.userService = userService;
        this.treatmentsService = treatmentsService;
        this.snackBar = snackBar;
        this.isLoading = true;
        this.isRequired = false;
        this.isEditing = false;
        this.isEditingRowIterator = 0;
        this.addresses = [];
        this.saveAddress = new EventEmitter();
        this.tempRows = [];
        this.tempAddresses = [];
        this.autocompleteEndpoint = '/api/Country/AutoComplete';
        this.selectedRowdAddress = false;
        this.langLoaded = false;
        this.isUsaVersion = environment.isUsaVersion;
    }
    ngAfterViewInit() {
        const interval = setInterval(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
        setTimeout(() => {
            clearInterval(interval);
        }, 2500);
    }
    defineColumns() {
        let cityLabel = 'REGISTRATION.COLUMNS.CityColumn';
        let stateLabel = 'REGISTRATION.COLUMNS.CountryColumn';
        let zipcodeLabel = 'REGISTRATION.COLUMNS.ZipCodeColumn';
        let vatLabel = 'REGISTRATION.VatNumberPlaceHolder';
        if (this.isUsaVersion) {
            cityLabel = 'REGISTRATION.COLUMNS.CityColumnUSA';
            stateLabel = 'REGISTRATION.COLUMNS.CountryColumnUSA';
            zipcodeLabel = 'REGISTRATION.COLUMNS.ZipCodeColumnUSA';
            vatLabel = 'REGISTRATION.VatNumberPlaceHolderUSA2';
        }
        this.ngxColumns = [
            { prop: 'addressType', name: this.translocoService.translate("REGISTRATION.COLUMNS.AddressTypeColumn"), width: 100, headerTemplate: this.hdrTpl, cellTemplate: this.addressTypeTpl },
            { prop: 'activityName', name: this.translocoService.translate("REGISTRATION.COLUMNS.ActivityNameColumn"), width: 140, headerTemplate: this.hdrTpl, cellTemplate: this.activityNameTpl },
            { prop: 'address', name: this.translocoService.translate("REGISTRATION.COLUMNS.AddressColumns"), width: 120, headerTemplate: this.hdrTpl, cellTemplate: this.addressTpl },
            { prop: 'city', name: this.translocoService.translate(cityLabel), width: 110, headerTemplate: this.hdrTpl, cellTemplate: this.cityTpl },
            { prop: 'country', name: this.translocoService.translate(stateLabel), width: 110, headerTemplate: this.hdrTpl, cellTemplate: this.autocompleteTpl },
            { prop: 'zipCode', name: this.translocoService.translate(zipcodeLabel), width: 110, headerTemplate: this.hdrTpl, cellTemplate: this.zipCodeTpl },
            { prop: 'phone', name: this.translocoService.translate("REGISTRATION.COLUMNS.PhoneColumn"), width: 100, headerTemplate: this.hdrTpl, cellTemplate: this.phoneTpl },
            { prop: 'vat', name: this.translocoService.translate(vatLabel), width: 100, headerTemplate: this.hdrTpl, cellTemplate: this.vatTPL },
            { prop: 'actions', name: '', width: 160, cellTemplate: this.actions, frozenRight: true },
        ];
    }
    ngOnInit() {
        this.isLoading = true;
        // this.isABleToEdit = this.userService.readUserFromLocalStorage().profileComplete || this.userService.readUserFromLocalStorage().role == "AD";
        // console.log('isABleToEdit', this.isABleToEdit);
        this.defineColumns();
        this.recalculate();
        this.translocoService.langChanges$.subscribe(() => {
            this.translocoService.selectTranslate('REGISTRATION.COLUMNS.AddressTypeColumn').subscribe((value) => {
                this.defineColumns();
            });
        });
    }
    checkIfAddress() {
        return this.userService.readUserFromLocalStorage().role !== 'AG' ||
            this.userService.readUserFromLocalStorage().role === 'DI';
    }
    checkkEditAddress(row) {
        if (this.isEditing && this.isEditingRowIterator !== row.iterator) {
            return false;
        }
        if (this.userService.readUserFromLocalStorage().role === 'AG' ||
            this.userService.readUserFromLocalStorage().role === 'DI') {
            return false;
        }
        if (this.userService.readUserFromLocalStorage().role === 'DO') {
            if (!this.userService.readUserFromLocalStorage().profileComplete) {
                return true;
            }
            if (row.idAddress === 0) {
                // enable edit for new row created
                return true;
            }
            return !row.usedInTreatments;
        }
        else {
            return true;
        }
    }
    rowEdit(id, f) {
        this.isEditing = f == undefined ? true : false;
        this.isEditingRowIterator = id;
        let selectedRow = this.addresses.find(x => x.iterator == id);
        if (selectedRow) {
            if (!selectedRow.enabled) {
                this.tempRows.push(_.cloneDeep(selectedRow));
                selectedRow.enabled = !selectedRow.enabled;
                return;
            }
            if (!this.checkRowValidation(selectedRow, f)) {
                this.snackBar.open(this.translocoService.translate('REGISTRATION.errorMessageMandatory'), 'X', {
                    duration: 3000,
                    panelClass: 'warning',
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                });
                return;
            }
            if (this.rowToAdd && this.rowToAdd.iterator == selectedRow.iterator) {
                this.rowToAdd = null;
            }
            this.tempRows = this.tempRows.filter(x => x.iterator != id);
            selectedRow.enabled = !selectedRow.enabled;
            selectedRow = this.rowPrepare(selectedRow);
            this.saveAddress.emit(this.addresses);
            this.isRequired = false;
        }
    }
    decline(id) {
        this.isEditing = false;
        this.isEditingRowIterator = 0;
        this.isRequired = false;
        const selectedRow = this.addresses.find(x => x.iterator == id);
        const selectedPrevValue = this.tempRows.find(x => x.iterator == id);
        if (selectedRow && selectedPrevValue) {
            selectedRow.enabled = !selectedRow.enabled;
            selectedRow.activityName = selectedPrevValue.activityName;
            selectedRow.phone = selectedPrevValue.phone;
            selectedRow.address = selectedPrevValue.address;
            selectedRow.ZipCode = selectedPrevValue.ZipCode;
            selectedRow.city = selectedPrevValue.city;
            selectedRow.type = selectedPrevValue.type;
            selectedRow.typeValue = selectedPrevValue.typeValue;
            selectedRow.countryCode = selectedPrevValue.countryCode;
            // selectedRow.countryName = selectedPrevValue.countryName;
            selectedRow.idAddress = selectedPrevValue.idAddress;
            selectedRow.vat = selectedPrevValue.vat;
            this.tempRows = this.tempRows.filter(x => x.iterator != selectedRow.iterator);
        }
        else {
            this.addresses = this.addresses.filter(x => x.iterator != id);
        }
    }
    addNewRow() {
        const generatedRowId = this.addresses.length == 0 ? 0 : Math.max.apply(Math, this.addresses.map(function (o) { return o.iterator; })) + 1;
        this.rowToAdd = { enabled: true, addressType: 'R', iterator: generatedRowId, idAddress: 0 };
        this.addresses = [...this.addresses, _.clone(this.rowToAdd)];
        this.rowToAdd = null;
        const offset = Math.min(this.addresses.length / 3);
        this.table.offset = offset;
    }
    declineAddRow() {
        this.rowToAdd = null;
        this.addresses.pop();
    }
    deleteRow() {
        if (this.selectedAddress) {
            this.addresses = [...this.addresses.filter(x => x.iterator !== this.selectedAddress.iterator)];
            this.saveAddress.emit(this.addresses);
        }
        this.closeDeleteModal();
    }
    closeDeleteModal() {
        this.deleteRowConfirmation.close();
    }
    recalculate() {
        this.addresses.forEach((x, i) => {
            x = this.rowPrepare(x);
            x.iterator = i;
        });
        this.addresses = [...this.addresses];
        this.tempAddresses = [...this.addresses];
        this.isLoading = false;
    }
    rowPrepare(address) {
        address.enabled = false;
        address.idAddress = address.idAddress ? address.idAddress : 0;
        const type = AddressTypes.find(x => x.key == address.addressType);
        if (type) {
            address.addressTypeValue = type.value;
        }
        return address;
    }
    // selectCounty(addres: any, country) {
    //   let selectedRow = this.addresses.find(x => x.iterator == addres.iterator);
    //   selectedRow.countryCode = country.key;
    //   selectedRow.countryName = country.value;
    // }
    checkRowValidation(selectedRow, f) {
        this.isRequired = false;
        Object.keys(f.controls).forEach((key) => {
            this.isRequired = true;
            f.controls[key].markAsTouched();
        });
        let result = true;
        if (!selectedRow.addressType) {
            result = false;
        }
        if (!selectedRow.address || selectedRow.address.length > 255) {
            this.selectedRowdAddress = true;
            result = false;
        }
        if (!selectedRow.zipCode || selectedRow.zipCode.length > 12) {
            result = false;
        }
        if (!selectedRow.city) {
            result = false;
        }
        // if (!selectedRow.countryName) {
        //   result = false;
        // }
        if (!selectedRow.countryCode) {
            result = false;
        }
        if (selectedRow.addressType == 'I' && !selectedRow.vat) {
            result = false;
        }
        return result;
    }
    openDeleteRowModal(tempalteRef, row) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isAbleToBeDeleted = false;
            if (this.userService.readUserFromLocalStorage().role != 'AD') {
                this.isAbleToBeDeleted = (yield this.treatmentsService.existByIdAddress(row.idAddress).toPromise());
            }
            this.selectedAddress = row;
            this.deleteRowConfirmation = this.modalServide.open(tempalteRef, { closeOnNavigation: true });
        });
    }
}
