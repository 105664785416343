<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;" class="change-password-wrapper">
    <div class="register mat-elevation-z12" >
      <div class="register-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
        <img class="logo" src="assets/img/nuvola_logo_white.png">
        <h5 class="panel-title">NuvolaWeb 4.0</h5>
        <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>
      </div>
      <form [formGroup]="ngForm" (ngSubmit)="changePassword()" *ngIf="!isLoading">
        <div class="register-content" fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field>
                <input matInput placeholder="{{'CHANGEPASSWORD.OldPasswordPlaceHolder' | transloco}}" [type]="hideOldPassword? 'password' : 'text'" name="password" required formControlName="oldPassword" >
                <button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="{{'RESET.PasswordPlaceHolder' | transloco}}" [type]="hideNewPassword? 'password' : 'text'" name="password" required formControlName="newPassword" >
                <button mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="{{'RESET.PasswordConfirmPlaceHolder' | transloco}}" [type]="hideNewConfirm? 'password' : 'text'" name="password-confirm" required formControlName="confirmPassword">
                <button mat-icon-button matSuffix (click)="hideNewConfirm = !hideNewConfirm" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hideNewConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <div class="extra-options" fxLayout="row" fxLayoutAlign="center center">
                <small>{{'RESET.InfoText' | transloco}}</small>
            </div>
            <button color="primary" mat-raised-button [disabled]="!ngForm.valid">{{'CHANGEPASSWORD.ChangeButton' | transloco}}</button>
            <p class="note">
            <a [routerLink]="['/']">{{'RESET.LogInLink' | transloco}}</a>
            </p>
        </div>
      </form>
      <div *ngIf="isLoading"
      fxLayout="row" fxLayoutAlign="center center"
      >
        <mat-progress-spinner
        class="margin"
        [mode]="'indeterminate'"
        [color]="'accent'"
        [value]="10"
        >
        </mat-progress-spinner>
      </div>
    </div>
  </div>
