import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { NewPatientComponent } from '../new-patient/new-patient/new-patient.component';
import { TreatmentsNextPhaseComponent } from '../treatments-next-phase/treatments-next-phase.component';
import * as i0 from "@angular/core";
export class CanDeactivateGuard {
    constructor() { }
    canDeactivate(component, currentRoute, currentState, nextState) {
        console.log('CanDeactivateGuard = ', component);
        if (component instanceof TreatmentsNextPhaseComponent) {
            console.log('ignoreModal', component.ignoreModal);
            if (!component.ignoreModal) {
                return component.openExitWithoutSaveModal();
            }
        }
        else if (component instanceof NewPatientComponent) {
            //console.log('NewPatientComponent');
            if (component.currentUser && component.currentUser.role == 'DO' &&
                component.selectedTreatment && component.selectedTreatment.status == 'DRAFT' &&
                (component.selectedTreatment.step1Complete && component.selectedTreatment.step2Complete &&
                    component.selectedTreatment.step3Complete && component.selectedTreatment.step4Complete &&
                    component.selectedTreatment.step5Complete && component.selectedTreatment.step6Complete)) {
                return component.openCheckSendToNuvolaModal('sendToNuvola');
            }
            else if (component.currentUser && component.currentUser.userGroup == 'PJ' && !component.treatmentPlanAllMandatoryFieldsCompleted &&
                component.selectedTreatment && component.selectedTreatment.status == 'INPROJECT' && !component.treatmentPlanAllMandatoryFieldsCompleted) {
                console.log('check for PJ');
                return component.openCheckSendToNuvolaModal('savePlan');
            }
        }
        return true;
    }
}
CanDeactivateGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanDeactivateGuard_Factory() { return new CanDeactivateGuard(); }, token: CanDeactivateGuard, providedIn: "root" });
