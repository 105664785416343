import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragAndDropFileComponent } from './drag-and-drop-file.component';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { MatButtonModule, MatCardModule, MatIconModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [DragAndDropFileComponent, DragAndDropDirective],
  imports: [
    CommonModule,
    TranslocoModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatProgressSpinnerModule 
  ],
  exports:[DragAndDropFileComponent, DragAndDropDirective],
  providers:[
    {
      provide:TRANSLOCO_SCOPE, useValue:{scope:"draganddropfile"}
    },
  ]
})
export class DragAndDropFileModule { }
