import {Pipe} from '@angular/core';
import { ConvertService } from '../../../pages/shared/services/convert.service';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'longDate'
})
export class LongDatePipe {

  constructor(
    private convertService: ConvertService,
  ) {}

  transform(value: any): string {
    return this.convertService.dateConvert(value, environment.dateFormat + ' HH:mm');
  }
}
