import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class MessagingServiceService {
    constructor() {
        this._message = new Subject();
    }
    sendMessage(message) {
        this._message.next(message);
    }
    getMessage() {
        return this._message.asObservable();
    }
}
MessagingServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagingServiceService_Factory() { return new MessagingServiceService(); }, token: MessagingServiceService, providedIn: "root" });
