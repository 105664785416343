<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="login mat-elevation-z12">
    <div class="login-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
      <img class="logo" src="assets/img/nuvola_logo_white.png">
      <h5 class="panel-title">NuvolaWeb 4.0</h5>
      <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="formSubmit()">
      <div class="login-content" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field>
          <input matInput placeholder="{{ 'LOGIN.EmailPlaceHolder' | transloco}}" type="email" name="email" required formControlName="email">
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="{{ 'LOGIN.PasswordPlaceHolder' | transloco}}" [type]="hide? 'password' : 'text'" name="password" required formControlName="password">
          <button mat-icon-button matSuffix (click)="hide = !hide" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div class="extra-options" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-slide-toggle class="remember-me" formControlName="remember">{{ 'LOGIN.RememberMe' | transloco}}</mat-slide-toggle>
          <a class="forgot-password" [routerLink]="['/forgot-password']">{{ 'LOGIN.ForgotPassowrdLink' | transloco}}</a>
        </div>
        <div *ngIf="isLoading"
        fxLayout="row" fxLayoutAlign="center center"
        >
          <mat-progress-spinner
          class="margin"
          [mode]="'indeterminate'"
          [color]="'primary'"
          [value]="10"
          >
          </mat-progress-spinner>
        </div>
    
        <button color="primary" mat-raised-button [disabled]="!loginForm.valid || isLoading" *ngIf="!isLoading">
          {{ 'LOGIN.LogInButton' | transloco}}
        </button>
        <p class="note">{{ 'LOGIN.Note' | transloco}}<br/> <a [routerLink]="['/register']">{{ 'LOGIN.RegisterLink' | transloco}}</a></p>
      </div>
    </form>
  </div>
</div>
