import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpperArchTableComponent } from './upper-arch-table.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MultiSelectCheckboxModule } from 'src/app/pages/components/multi-select-checkbox/multi-select-checkbox.module';
import { ComponentsModule } from 'src/app/pages/components/components.module';



@NgModule({
  declarations: [UpperArchTableComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FlexModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoModule,
    ComponentsModule,
    MultiSelectCheckboxModule
  ],
  exports:[UpperArchTableComponent]
})
export class UpperArchTableModule { }
