<div class="user-button-container" #userButton (vrClickOutside)="onClickOutside()">
  <button class="user-button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <span class="avatar-container" fxLayout="row" fxLayoutAlign="start center">
        <img class="avatar" [src]="currentUser.photoFullUrl" *ngIf="currentUser.photoFileName">
        <img class="avatar" src="assets/img/demo/avatars/noavatar.png" *ngIf="!currentUser.photoFileName">
      </span>
      <span class="name" fxHide fxShow.gt-sm>{{currentUser.firstName + " " + currentUser.lastName}}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple
          (click)="openEditCreateModeal(edtiCreateModal)">
          <mat-icon class="icon">account_circle</mat-icon>
          <span class="title">
            {{ 'toolbar.USERCOMPONENT.Profile' | transloco}}
          </span>
        </div>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple
          (click)="openEditCreatePhotoModeal(edtiCreatePhotoModal)">
          <mat-icon class="icon">perm_contact_calendar</mat-icon>
          <span class="title">
            {{ 'toolbar.USERCOMPONENT.ImageProfile' | transloco}}
          </span>
        </div>
        <div class="item" *ngIf="showMyUsersMenuItem" fxLayout="row" fxLayoutAlign="start center" matRipple
          (click)="redirectTo('users-group')">
          <mat-icon class="icon">people</mat-icon>
          <span class="title">
            {{ 'toolbar.USERCOMPONENT.MyUsers' | transloco}}
          </span>
        </div>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple *accessControl="{'id':'AD'}"
          (click)="redirectTo('holidays')">
          <mat-icon class="icon">https</mat-icon>
          <span class="title">
            {{ 'toolbar.ROUTES.Holidays' | transloco}}
          </span>
        </div>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple *accessControl="{'id':'AD'}"
          (click)="openSettingsModal(settingsModal)">
          <mat-icon class="icon">settings</mat-icon>
          <span class="title">
            {{ 'toolbar.USERCOMPONENT.Settings' | transloco}}
          </span>
        </div>
        <div class="divider"></div>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" matRipple (click)="openConfirmModal()">
          <mat-icon class="icon">exit_to_app</mat-icon>
          <span class="title">
            {{ 'toolbar.USERCOMPONENT.Logout' | transloco}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #edtiCreateModal let-modal="modal">
  <nuvolaweb-create-update-user [selectedRow]="currentUser" [isMyProfileClick]="true"
    (createUpdate)="createUpdateHandler($event)">
  </nuvolaweb-create-update-user>
</ng-template>


<ng-template #edtiCreatePhotoModal let-modal="modal">
  <nuvolaweb-create-update-user-photo (createUpdatePhoto)="createUpdatePhotoHandler($event)">
  </nuvolaweb-create-update-user-photo>
</ng-template>


<ng-template #settingsModal let-modal="modal">
  <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner class="margin" [mode]="'indeterminate'" [color]="'accent'" [value]="10">
    </mat-progress-spinner>
  </div>
  <div fxFlex fxLayout="column" *ngIf="!isLoading">
    <mat-card fxFlex="100%" fxFlex.gt-sm fxLayout="column">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
        </div>
        <button type="reset" mat-icon-button align="end" (click)="closeSettings()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-form-field fxFlex>
        <input type="text" [formControl]="alignersPerDay" matInput
          placeholder="{{'toolbar.alignersPerDayLabel' | transloco}}">
      </mat-form-field>
    </mat-card>
    <div fxLaout='row' fxLayoutAlign="space-between center">
      <div fxFlex fxLayout='row' fxLayoutAlign="end" *ngIf="!isEditLoading" style="padding-top: 7px;"
        fxLayoutGap="16px">
        <button mat-raised-button color='warn' type="button" (click)="closeSettings()">
          {{'COMMON.CancelButton' | transloco}}
        </button>
        <button mat-raised-button class="btn-default" (click)="saveDays()">
          {{'COMMON.SaveButton' | transloco}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #clinicalPreferences let-modal="modal">
  <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner class="margin" [mode]="'indeterminate'" [color]="'accent'" [value]="10">
    </mat-progress-spinner>
  </div>
  <div fxFlex fxLayout="column" *ngIf="!isLoading">
    <mat-card fxFlex="100%" fxFlex.gt-sm fxLayout="column">
      <mat-form-field fxFlex>
        <textarea [formControl]="clinicPreferencesInput" matInput rows="3" maxlength="1000"
          placeholder="{{'toolbar.USERCOMPONENT.clinicPreferences' | transloco}}"></textarea>
      </mat-form-field>
    </mat-card>
    <div fxLaout='row' fxLayoutAlign="space-between center">
      <div fxFlex fxLayout='row' fxLayoutAlign="end" *ngIf="!isEditLoading" style="padding-top: 7px;"
        fxLayoutGap="16px">
        <button mat-raised-button color='warn' type="button" (click)="closeClinicalPreferences()">
          {{'COMMON.CancelButton' | transloco}}
        </button>
        <button mat-raised-button class="btn-default" (click)="saveClinicalPreferences()">
          {{'COMMON.SaveButton' | transloco}}
        </button>
      </div>
    </div>
  </div>
</ng-template>
