import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../../modules/security/models/user.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { httpHeaders } from '../../../environments/httpHeaders';
import { UserService } from '../../modules/security/services/user.service';
import { JwtHelperService } from '@auth0/angular-jwt';


const apiUrl = environment.apiUrl;
const headers = httpHeaders.headers;


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {



  private user:User;
  public emailToChangePassword:string = null;

  constructor(
    private _http:HttpClient,
    private userService:UserService,
    private jwtHelper:JwtHelperService
    ) { this.user = this.userService.readUserFromLocalStorage(); }

  public loginUser(username:string, password:string, remember?:boolean){
    const endPoint = "/api/token/"
    var request = {
      username:username,
      password:password,
      rememberMe:remember?true:false
    }

    return this._http.post(apiUrl + endPoint, request, {headers:headers, observe:'response'}).pipe(
      map(response => {
        this.user = new User(response.body);
        
        if(this.user && this.user.accessToken){
          localStorage.setItem("nuvolaUser", JSON.stringify(this.user));
          localStorage.setItem("nuvola_session_token", this.user.accessToken);
          localStorage.setItem("nuvola_refresh_token", this.user.refreshToken);
        }
        return this.user;
      }
    ));
  }

  public async logOutUser(){
    if(this.user && this.user.accessToken){
      const endPoint = "/api/token/logout";
      var response = await this._http.post(apiUrl + endPoint, null, {headers:headers, observe:"response"}).toPromise();
      console.log("LogOut:" + response.status + " " + response.statusText);
    }
    this.clearStorage();
  }

  public clearStorage() {
    localStorage.removeItem("nuvolaUser");
    localStorage.removeItem("nuvola_session_token");
    localStorage.removeItem("nuvola_refresh_token");
  }

  public isLoggedIn(){
    this.user = this.userService.readUserFromLocalStorage();
    return !!this.user && (this.user.accessToken);
  }

  public isTokenExpired(){
    return this.jwtHelper.isTokenExpired(this.user.accessToken);
  }

  public getToken(){
    return this.user.accessToken;
  }

  public getRefreshToken(){
    return this.user.refreshToken;
  }

  public refreshToken(){
    var endPoint = "/api/token/refresh-token";
    var reques = {
      "refreshToken":this.user.refreshToken
    }
    return this._http.post(apiUrl + endPoint, reques, {headers:headers, observe:"response"}).pipe(map((resp:any) => {
      console.log("Refresh Token: " + resp.status + " " + resp.statusText);
      this.user.accessToken = resp.body.accessToken;
      this.user.refreshToken = resp.body.refreshToken;
      localStorage.removeItem("nuvolaUser");
      localStorage.removeItem("nuvola_session_token");
      localStorage.removeItem("nuvola_refresh_token");
      localStorage.setItem("nuvolaUser", JSON.stringify(this.user));
      localStorage.setItem("nuvola_session_token", this.user.accessToken);
      localStorage.setItem("nuvola_refresh_token", this.user.refreshToken);
      return this.user.accessToken; 
    }, error => {
      return false;
    }));
  }

  async refreshTokenTrigger() {
    return await this.refreshToken().toPromise();
  }

  getExpireTokenTime(){
    this.user = this.userService.readUserFromLocalStorage();
    return this.jwtHelper.getTokenExpirationDate(this.user.accessToken);
  }

  public isUserInRole(role:string|string[]){
    if(Array.isArray(role)){
      return role.includes(this.user.role);
    }else{
      return this.user.role === role;
    }
  }

  isUserInGroup(userGroup: string|string[]) {
    if(Array.isArray(userGroup)){
      return userGroup.includes(this.user.userGroup);
    }else{
      return this.user.userGroup === userGroup;
    } }

  public forgorPassword(email:string){
    const endPoint = "/api/admin/forgotpassword";
    var request = {
      email:email
    };

    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public sendConfirmEmailToDoctor(idAdmin:number){
    const endPoint = "/api/admin/doctor/sendconfirmemail";
    var request = {
      idAdmin:idAdmin
    };

    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public checkResetPassword(code:string){
    const endPoint = "/api/admin/checkresetpassword";
    var request = {
      resetToken:code
    };

    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public resetPassword(code:string, password:string){
    const endPoint = "/api/admin/resetpassword";
    var request = {
      resetToken:code,
      newPassword:password
    };

    return this._http.post(apiUrl + endPoint, request, {headers:headers});
  }

  public changePassword(request: any) {
    const endPoint = "/api/Admin/ChangePassword";
    return this._http.post(`${apiUrl}${endPoint}`, request, {headers:headers});
  }

}
