<div *ngIf="item.routeIsFunction(); then function else route"></div>

<ng-template #function>
  <a class="sidenav-item" [class.open]="isOpen(item)" matRipple
     (click)="item.route"
     fxLayout="row" fxLayoutAlign="start center"
     *ngIf="!item.hasSubItems()">

    <span class="name">{{ item.name | transloco }}</span>
    <span fxFlex></span>
    <mat-icon class="icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
    <div class="letter-icon" *ngIf="item.hasParent()">{{ item.generateLetterIcon() }}</div>
    <!--<span class="badge fade-in-on-icon-sidenav" *ngIf="item.badge" [style.background-color]="item.badgeColor">{{ item.badge }}</span>-->
  </a>
</ng-template>

<ng-template #route>
  <a class="sidenav-item" [class.open]="isOpen(item)" matRipple
     [routerLink]="item.route" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
     fxLayout="row" fxLayoutAlign="start center"
     *ngIf="!item.hasSubItems()">

    <span class="name">{{ item.name | transloco }}</span>
    <span fxFlex></span>
    <mat-icon class="icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
    <!-- <div class="letter-icon" *ngIf="item.hasParent()">{{ item.generateLetterIcon() }}</div> -->
  </a>
</ng-template>

<a class="sidenav-item" [class.open]="isOpen(item)" matRipple
   (click)="toggleDropdown()"
   fxLayout="row" fxLayoutAlign="start center"
   *ngIf="item.hasSubItems()">

  <span class="name">{{ item.name | transloco}}</span>
  <span fxFlex></span>
  <mat-icon *ngIf="item.hasSubItems()" class="dropdown-indicator" [class.rotate]="isOpen(item)">expand_more</mat-icon>
  <mat-icon class="icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
  <!-- <div class="letter-icon" *ngIf="item.hasParent()">{{ item.generateLetterIcon() }}</div> -->
</a>

<div class="sub-menu" [style.height]="getSubMenuHeight()">
  <elastic-sidenav-item class="sidenav-item-container" *ngFor="let subItem of item.subItems" [item]="subItem" [currentlyOpen]="currentlyOpen"></elastic-sidenav-item>
</div>
