import { Component, Input, OnInit, forwardRef, Directive, Renderer, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckboxState, TypeIpr } from '../../../../core/enums';

@Component({
  selector: 'nuvolaweb-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: ['./multi-select-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => MultiSelectCheckboxComponent)
  }]
})

export class MultiSelectCheckboxComponent implements OnInit, ControlValueAccessor {

  public CheckboxStateEnum = CheckboxState;
  //@Input() states = {checkboxState: '', type: ''}
  @Input('currentState') currentState = CheckboxState.White;
  @Input('typeIPR') typeIPR = TypeIpr.None;
  @Input('checkboxDisabled') checkboxDisabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};
  isDisabled = false;
  private _value = undefined;


  constructor() 
  { 
    this._value = this.getDefaultValue();
  }

  writeValue(value: any): void {
   // this.currentState = obj;
  //  if (value) {
  //   debugger;
  //  }
  //  console.log('writeValue', value);
   //this.selectNextState();
   if (!this.areValuesEqual(value, this._value)) {
      var value_intermediate = (value && value instanceof Object) ? value : this.getDefaultValue();

      //console.log('writeValue', value, value_intermediate, this.currentState, this.typeIPR);

      this.currentState = value_intermediate.currentState;
      this.typeIPR = value_intermediate.typeIPR;
      this.setValueObject();
      
      // this.fireChangedEvent();
      // this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
   }
  }

  registerOnChange(fn: any): void {
     this.onChange = fn;
   // console.log("registerOnChange\n" + fn);
  }
  registerOnTouched(fn: any): void {
    //this.selectNextState();
    //console.log("registerOnTouched\n" + fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.fireChangedEvent();
  }

  ngOnInit() {
  }

  selectNextState() {

    if (this.checkboxDisabled)
      return;

    if(this.currentState === CheckboxState.White) { this.currentState = CheckboxState.Red; }
    else if(this.currentState === CheckboxState.Red) { this.currentState = CheckboxState.Blue; }
    else if(this.currentState === CheckboxState.Blue) { this.currentState = CheckboxState.Green; }
    else if(this.currentState === CheckboxState.Green) { this.currentState = CheckboxState.White; }

    this.setValueObject();
    //console.log('next state', this._value);
    this.fireChangedEvent();
  }

  selectNextTypeIpr() {

    if (this.checkboxDisabled)
      return;

    if(this.typeIPR === TypeIpr.None) { this.typeIPR = TypeIpr.Mesial; }
    else if(this.typeIPR === TypeIpr.Mesial) { this.typeIPR = TypeIpr.Distal; }
    else if(this.typeIPR === TypeIpr.Distal) { this.typeIPR = TypeIpr.None; }

    this.setValueObject();
    this.fireChangedEvent();
  }

  public resetData() {
    this.currentState = CheckboxState.White;
    this.typeIPR = TypeIpr.None;
  }

  private fireChangedEvent() {
    if (this.onChange && this.onChange instanceof Function) {
      this.onChange(this._value);
    }
  };

  private setValueObject(): void {
    this._value = {
      currentState: this.currentState, 
      typeIPR: this.typeIPR
    };
  }

  private areValuesEqual(value1: any, value2: any): boolean {
    return (value1 && value2) ?
      (value1.currentState == value2.currentState && value1.typeIPR == value2.typeIPR) :
      false;
  }

  private getDefaultValue(): any {
    return {
      currentState: CheckboxState.White,
      typeIPR: TypeIpr.None
    };
  }
}
