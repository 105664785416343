import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PatientService } from '../patient.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'nuvolaweb-treatment-modal',
  templateUrl: './treatment-modal.component.html',
  styleUrls: ['./treatment-modal.component.scss']
})
export class TreatmentModalComponent implements OnInit {

  constructor(
    private _patientService: PatientService,
    private snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  idTreatment: any;
  modalType:string;
  
  ngOnInit() {
    this.modalType = this.data.modalType;
  }

  doSend() {
    this._patientService.changeStatus(null, this.data.idTreatment, "evaluation").subscribe(resp => {
      this.snackBar.open(this._translocoService.translate("patient.ADDITIONAL.sendToNuvolaMessageConfirm"), "X", {
        duration: 3000,
        panelClass: "success",
        horizontalPosition: "right",
        verticalPosition: "top",
      } as MatSnackBarConfig);
    });
  }

  doMove(){
    
  }

  doClose() {
    
  }

}
