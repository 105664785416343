<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
  <div class="forgot mat-elevation-z12">
    <div class="forgot-header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="40px">
      <img class="logo" src="assets/img/nuvola_logo_white.png">
      <h5 class="panel-title">NuvolaWeb 4.0</h5>
      <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>
    </div>
    <form [formGroup]="ngForm" (ngSubmit)="send()" *ngIf="!isPreloadEnd && !isLoading">
      <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field>
          <input matInput placeholder="{{'FORGOT.EmailPlaceHolder' | transloco}}" type="email" name="email" required formControlName="email">
        </mat-form-field>
        <button color="primary" mat-raised-button [disabled]="!ngForm.valid">{{'FORGOT.RecoverPasswordButton' | transloco}}</button>
        <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
      </div>
    </form>
    <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!success && isPreloadEnd">
      <p class="errorMessage">{{'FORGOT.errorMessage' | transloco}}</p>
      <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
    </div>
    <div class="forgot-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="success && isPreloadEnd">
      <p class="successMessage">{{'FORGOT.successMessage' | transloco}}</p>
      <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
    </div>
    <div *ngIf="isLoading"
    fxLayout="row" fxLayoutAlign="center center"
    >
      <mat-progress-spinner
      class="margin"
      [mode]="'indeterminate'"
      [color]="'accent'"
      [value]="10"
      >
      </mat-progress-spinner>
    </div>
  </div>
</div>