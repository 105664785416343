import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/modules/security/services/user.service';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { ContentsService } from '../../contents/services/contents.service';
import { AuthenticationService } from '../authentication.service';

declare function hideTawkChat(): any;    //js function tohide Tawk Chat widget

@Component({
  selector: 'elastic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class LoginComponent implements OnInit {

  public loginForm:FormGroup;
  public isLoading:boolean;
  public hide:boolean = true;

  constructor(
    private router: Router,
    private authService:AuthenticationService,
    private fb: FormBuilder,
    private snackBar:MatSnackBar,
    private _translocoService:TranslocoService,
    private _contentService: ContentsService,
    private _userService: UserService
  ) { }

  ngOnInit() {   
    this.authService.emailToChangePassword = null;
    this.loginForm = this.fb.group({
      email:[null, [Validators.required]],
      password:[null, Validators.required],
      remember:[null]
    });
    if(this.authService.isLoggedIn()){
      this.router.navigate(["/dashboard"]);
    }else{
      hideTawkChat();
      this.authService.logOutUser();
    }
  }

  formSubmit() {
    this.isLoading = true;
    if(this.loginForm.valid){
      this.authService.loginUser(this.loginForm.controls.email.value, this.loginForm.controls.password.value, this.loginForm.controls.remember.value).subscribe(async result => {
        
        if(this.authService.isUserInRole(["DO", "AG", "DI", "SA", "EX"])){
          let result = await this._contentService.getNewsContent(false).toPromise();
          this._contentService.setContentHolderCountValue("news", result.length);
        }
        
        if(result){
          if (result.profileComplete)
            this.router.navigate(['/dashboard']);
          else
          this.router.navigate(['/myprofile']);
        }
      }, (error:HttpErrorResponse) => {
        if(error.status == 403){
          this.authService.emailToChangePassword = this.loginForm.controls.email.value;
          this.snackBar.open(this._translocoService.translate("LOGIN.passwordExpired"), "X", {
            direction:"rtl",
            duration:3000,
            panelClass:"warning",
            horizontalPosition:"right",
            verticalPosition:"top",
          } as MatSnackBarConfig);
          this.router.navigate(['/change-password']);
          return;
        }
        this.snackBar.open(error.error.message, "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
        this.isLoading = false;
      });
    }else{
      this.isLoading = false;
    }
    return;
  }

}
