import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../../../pages/auth/authentication.service';
import { AccessControlModel } from '../../../modules/security/models/access-control.model';

@Directive({
  selector: '[accessControl]'
})
export class AccessControlDirective implements OnInit {

  @Input() accessControl: AccessControlModel;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthenticationService
  ) { }


  ngOnInit(): void {
    this.viewContainerRef.clear();
    if (this.accessControl && this.accessControl.id &&
      (Array.isArray(this.accessControl.id) ? this.accessControl.id.length > 0 : this.accessControl.id)) {
      if (this.authService.isUserInRole(this.accessControl.id)
          && (this.accessControl.userGroup ? this.authService.isUserInGroup(this.accessControl.userGroup) : true)) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
