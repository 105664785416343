import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatInputModule, MatOptionModule, MatProgressSpinnerModule, MatSelectModule, MatSlideToggleModule, MatSortModule, MatTableModule } from '@angular/material';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { CreateUpdateUserComponent } from './create-update-user.component';
import { GenericAutocompleteInputModule } from '../generic-autocomplete-input/generic-autocomplete-input.module';
import { DoctorAddressGridModule } from '../doctor-address-grid/doctor-address-grid.module';
import { TreatmentResonsModule } from 'src/app/pages/new-patient/treatment-resons/treatment-resons.module';

@NgModule({
  declarations: [CreateUpdateUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    SharedModule,
    MatCheckboxModule,
    MatCardModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    GenericAutocompleteInputModule,
    DoctorAddressGridModule,
    MatSlideToggleModule,
    TreatmentResonsModule
  ],
  exports:[
    CreateUpdateUserComponent
  ]
})
export class CreateUpdateUserModule { }
