import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'nuvolaweb-treatments-next-phase-modal',
  templateUrl: './treatments-next-phase-modal.component.html',
  styleUrls: ['./treatments-next-phase-modal.component.scss']
})
export class TreatmentsNextPhaseModalComponent implements OnInit {

  constructor(
    private snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TreatmentsNextPhaseModalComponent>
  ) { }

  idTreatment: any;
  
  ngOnInit() {
  }

  doMove() {
    this.dialogRef.close(true);
  }

  doStay() {
     this.dialogRef.close(false);
  }

}
