import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ToolbarModule } from '../../../core/toolbar/toolbar.module';
import { MatCardModule, MatIconModule, MatOptionModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { GenericAutocompleteInputModule } from '../../components/generic-autocomplete-input/generic-autocomplete-input.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToolbarModule,
    MatCardModule,
    MatIconModule,
    GenericAutocompleteInputModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule
  ],
  declarations: [RegisterComponent],
  exports:[RegisterComponent],
  providers:[
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { 
        siteKey: environment.recaptchaSiteKeyClient,
      } as RecaptchaSettings,
    }
  ]
})
export class RegisterModule { }

