/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumbs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "./breadcrumbs.component";
var styles_BreadcrumbsComponent = [i0.styles];
var RenderType_BreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbsComponent, data: {} });
export { RenderType_BreadcrumbsComponent as RenderType_BreadcrumbsComponent };
export function View_BreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "breadcrumbs"]], [[2, "padding-top", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "breadcrumbs"; var currVal_2 = _co.header; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.header; var currVal_4 = "breadcrumbs"; _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 0, 0, currVal_0); }); }
export function View_BreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-breadcrumbs", [], null, null, null, View_BreadcrumbsComponent_0, RenderType_BreadcrumbsComponent)), i1.ɵdid(1, 114688, null, 0, i5.BreadcrumbsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbsComponentNgFactory = i1.ɵccf("elastic-breadcrumbs", i5.BreadcrumbsComponent, View_BreadcrumbsComponent_Host_0, { currentPage: "currentPage", names: "names", routes: "routes", header: "header" }, {}, []);
export { BreadcrumbsComponentNgFactory as BreadcrumbsComponentNgFactory };
