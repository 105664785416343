import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatInputModule, MatOptionModule, MatProgressSpinnerModule, MatSelectModule, MatSlideToggleModule, MatSortModule, MatTableModule } from '@angular/material';
import { SharedModule } from '../../../core/shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { GenericAutocompleteInputModule } from '../generic-autocomplete-input/generic-autocomplete-input.module';
import { DoctorAddressGridModule } from '../doctor-address-grid/doctor-address-grid.module';
import { CreateUpdateUserPhotoComponent } from './create-update-user-photo.component';
import { DragAndDropFileModule } from '../drag-and-drop-file/drag-and-drop-file.module';



@NgModule({
  declarations: [CreateUpdateUserPhotoComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    SharedModule,
    MatCheckboxModule,
    MatCardModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    GenericAutocompleteInputModule,
    DoctorAddressGridModule,
    MatSlideToggleModule,
    DragAndDropFileModule
  ],
  exports: [CreateUpdateUserPhotoComponent]
})
export class CreateUpdateUserPhotoModule { }
