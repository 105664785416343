<div class="table-wrapper" #tableWrapper fxLayoutGap="10px" fxLayout="column">
    <ngx-datatable
    #table [columns]="ngxColumns" [externalPaging]="false" [externalSorting]="false"
    [footerHeight]="'auto'" [reorderable]="false" [rows]="addresses" class="material"
    [rowHeight]="'auto'" [headerHeight]="'auto'" [footerHeight]="50" [limit]="5"
    [scrollbarH]="true" [loadingIndicator]="isLoading" [columnMode]="'force'"
    >
    </ngx-datatable>
    <div fxLayout='row' fxLayoutAlign="end end" *ngIf="checkIfAddress()">
        <button type="button" (click)="addNewRow()" #addButton mat-raised-button color="accent" [disabled]="isEditing">
            {{'REGISTRATION.addNewAddressButton' | transloco}}
        </button>
    </div>
</div>


<ng-template #hdrTpl let-column="column" let-sort="sortFn">
    <span (click)="sort()" class="column-name">{{ column.name }}</span>
</ng-template>
<ng-template #addressTypeTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.addressTypeValue | transloco}}</span>
    <mat-form-field *ngIf="row.enabled" required>
        <mat-select [(ngModel)]="row.addressType" style='width: 100px;'>
          <mat-option value="R" *ngIf="!isUsaVersion">
            {{'REGISTRATION.returnTypeValue' | transloco}}
          </mat-option>
          <mat-option value="S">
            {{'REGISTRATION.shipTypeValue' | transloco}}
          </mat-option>
          <mat-option value="I">
            {{'REGISTRATION.invoiceTypeValue' | transloco}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</ng-template>
<form #f="ngForm">
<ng-template #activityNameTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.activityName }}</span>
    <mat-form-field *ngIf="row.enabled" >
        <input maxlength="40" placeholder='{{"REGISTRATION.ActivityNamePlaceHolder" | transloco}}' matInput type="text" name="activityName" #activityName="ngModel"   [(ngModel)]="row.activityName">
    </mat-form-field>
</ng-template>
<ng-template #addressTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.address }}</span>
    <mat-form-field *ngIf="row.enabled">
        <input maxlength="255" placeholder='{{"REGISTRATION.AddressPlaceHolder" | transloco}}' matInput  type="text" required #address="ngModel" [(ngModel)]="row.address" name="address" >
      </mat-form-field>
</ng-template>
<ng-template #zipCodeTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.zipCode }}</span>
    <mat-form-field *ngIf="row.enabled">
        <input maxlength="12" placeholder='{{"REGISTRATION.ZipCodePlaceHolder" | transloco}}' matInput type="text" required name="zipCode" [(ngModel)]="row.zipCode">
    </mat-form-field>
</ng-template>
<ng-template #cityTpl let-row="row">
  <span *ngIf="!row.enabled" >{{ row.city }}</span>
  <mat-form-field *ngIf="row.enabled">
      <input placeholder='{{"REGISTRATION.CityPlaceHolder" | transloco}}' matInput type="text" required name="city" [(ngModel)]="row.city">
  </mat-form-field>
</ng-template>

<ng-template #vatTPL let-row="row">
  <span *ngIf="!row.enabled" >{{ row.vat }}</span>
  <mat-form-field *ngIf="row.enabled">
      <input placeholder='{{"REGISTRATION.VatNumberPlaceHolder" | transloco}}' matInput type="text" maxlength="20" required [(ngModel)]="row.vat" name ="vat" [required]="row.addressType == 'I'">
  </mat-form-field>
</ng-template>

<ng-template #autocompleteTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.countryCode }}</span>
    <mat-form-field *ngIf="row.enabled">
      <input placeholder='{{"REGISTRATION.CountryPlaceHolder" | transloco}}' matInput type="text" maxlength="60" required [(ngModel)]="row.countryCode" name ="countryCode" required>
    </mat-form-field>
    <!-- <div *ngIf="row.enabled">
        <nuvolaweb-generic-autocomplete-input
        [apiEndPoint]="autocompleteEndpoint"
        [placeHolder]="'REGISTRATION.CountryPlaceHolder'"
        [currentValue]="row.countryName"
        [isRequired]="isRequired"
        (onOptionSelect)="selectCounty(row, $event)"

        >
        </nuvolaweb-generic-autocomplete-input>
    </div> -->
</ng-template>

<ng-template #cityTpl let-row="row">
  <span *ngIf="!row.enabled" >{{ row.city }}</span>
  <mat-form-field *ngIf="row.enabled && !isUsaVersion">
      <input placeholder='{{"REGISTRATION.CityPlaceHolder" | transloco}}' matInput type="text" required name="city" [(ngModel)]="row.city">
  </mat-form-field>
  <mat-form-field *ngIf="row.enabled && isUsaVersion">
    <input placeholder='{{"REGISTRATION.CityPlaceHolderUSA" | transloco}}' matInput type="text" required name="city" [(ngModel)]="row.city">
</mat-form-field>
</ng-template>
<ng-template #zipCodeTpl let-row="row">
  <span *ngIf="!row.enabled" >{{ row.zipCode }}</span>
  <mat-form-field *ngIf="row.enabled && !isUsaVersion">
      <input maxlength="12" placeholder='{{"REGISTRATION.ZipCodePlaceHolder" | transloco}}' matInput type="text" required name="zipCode" [(ngModel)]="row.zipCode">
  </mat-form-field>
  <mat-form-field *ngIf="row.enabled && isUsaVersion">
    <input maxlength="12" placeholder='{{"REGISTRATION.ZipCodePlaceHolderUSA" | transloco}}' matInput type="text" required name="zipCode" [(ngModel)]="row.zipCode">
</mat-form-field>
</ng-template>
<ng-template #vatTPL let-row="row">
  <span *ngIf="!row.enabled" >{{ row.vat }}</span>
  <mat-form-field *ngIf="row.enabled && !isUsaVersion">
      <input placeholder='{{"REGISTRATION.VatNumberPlaceHolder" | transloco}}' matInput type="text" maxlength="20" required [(ngModel)]="row.vat" name ="vat" [required]="row.addressType == 'I'">
  </mat-form-field>
  <mat-form-field *ngIf="row.enabled && isUsaVersion">
    <input placeholder='{{"REGISTRATION.VatNumberPlaceHolderUSA2" | transloco}}' matInput type="text" maxlength="20" required [(ngModel)]="row.vat" name ="vat" [required]="row.addressType == 'I'">
</mat-form-field>
</ng-template>

<ng-template #phoneTpl let-row="row">
    <span *ngIf="!row.enabled" >{{ row.phone }}</span>
    <mat-form-field *ngIf="row.enabled">
        <input placeholder='{{"REGISTRATION.PhonePlaceHolder" | transloco}}' maxlength="30" matInput type="text" name="phone" [(ngModel)]="row.phone">
    </mat-form-field>
</ng-template>


<ng-template #actions let-row="row">
   <div fxLayout fxLayoutAlign="space-around center" class="rowIconContainer">
        <div *ngIf="checkkEditAddress(row)">
          <mat-icon *ngIf="!row.enabled" (click)="rowEdit(row.iterator)">edit</mat-icon>
        </div>
        <div *ngIf="checkkEditAddress(row)">
          <mat-icon *ngIf="!row.enabled" (click)="openDeleteRowModal(deleteRowConfirmation, row)">delete</mat-icon>
        </div>
        <mat-icon *ngIf="row.enabled" (click)="rowEdit(row.iterator,f)">check_circle</mat-icon>
        <mat-icon *ngIf="row.enabled" (click)="decline(row.iterator)">undo</mat-icon>
   </div>
</ng-template>

</form>
<ng-template #deleteRowConfirmation>
    <mat-card-content fxLayout="column" fxLayoutAlign="center center">
      <span  *ngIf="!isAbleToBeDeleted">
        {{'users.confirmDeleteMessage' | transloco}}
      </span>
      <span  *ngIf="isAbleToBeDeleted">
        {{'users.notAbleToBeDeleted' | transloco}}
      </span>
    </mat-card-content>
    <mat-card-actions >
      <div fxLayout="row" style="padding-top:25px;" fxLayoutGap="16px" fxLayoutAlign="center center ">
        <button mat-raised-button color="warn" (click)="closeDeleteModal()">
          {{'COMMON.CancelButton' | transloco}}
        </button>
        <button mat-raised-button class="btn-default" (click)="deleteRow()" *ngIf="!isAbleToBeDeleted">
          {{ 'COMMON.ConfirmButton' | transloco}}
        </button>
      </div>
    </mat-card-actions>
  </ng-template>
