import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationConfirmationComponent } from './registration-confirmation.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from 'src/app/core/toolbar/toolbar.module';
import { MatProgressSpinnerModule } from '@angular/material';



@NgModule({
  declarations: [RegistrationConfirmationComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslocoModule,
    RouterModule,
    ToolbarModule,
    MatProgressSpinnerModule
  ],
  exports:[RegistrationConfirmationComponent]
})
export class RegistrationConfirmationModule { }
