import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectCheckboxComponent } from './multi-select-checkbox/multi-select-checkbox.component';



@NgModule({
  declarations: [MultiSelectCheckboxComponent],
  imports: [
    CommonModule
  ],
  exports: [MultiSelectCheckboxComponent]
})
export class MultiSelectCheckboxModule { }
