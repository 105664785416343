import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarRef } from '@angular/material';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'elastic-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class ForgotPasswordComponent implements OnInit {

  snackBarRef:MatSnackBarRef<any>;
  ngForm:FormGroup;

  public isPreloadEnd:boolean = false;
  public success: boolean = false;
  public isLoading:boolean;

  constructor(
    private fb:FormBuilder,
    private authenticateService:AuthenticationService
  ) { }

  ngOnInit() {
    this.ngForm = this.fb.group({
      email:[null, [Validators.pattern(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]]
    });
  }

  send() {
    if(!this.ngForm.valid){
      this.ngForm.touched;
    }
    this.isLoading = true;
    this.authenticateService.forgorPassword(this.ngForm.controls.email.value).subscribe((response:boolean) => {
      if(response){
        this.success = response;
      }else {
        this.success = response;
      }
      this.isPreloadEnd = true
      this.isLoading = false;
    }, error => {
      this.isPreloadEnd = true
      this.success = false;
      this.isLoading = false;
    });
  }

  closeSnackBar(){
    if(this.snackBarRef){
      this.snackBarRef.dismiss();
    }
  }

}
