<div class="flag-selector-container" (vrClickOutside)="onClickOutside()" *ngIf="!isUsaVersion">
    <button class="flag-button" mat-button (click)="toggleDropdown()" [class.open]="isOpen">
      <span fxLayout="row" fxLayoutAlign="start center">
        <img mat-list-avatar src="{{currentLanguage.flagPath}}">
        <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
      </span>
    </button>

    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
        <mat-list>
            <mat-list-item
            class="languageRow"
            *ngFor="let lang of availableLanguages"
            (click)="onLanguageSelect(lang.id)"
            >
            <img mat-list-avatar style="width: 25px; height: 25px;" src="{{lang.flagPath}}">
            <h3 matLine>{{lang.label}}</h3>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div class="flag-selector-container" (vrClickOutside)="onClickOutside()" *ngIf="isUsaVersion">
  <button class="flag-button" mat-button [class.open]="isOpen">
    <span fxLayout="row" fxLayoutAlign="start center">
      <img mat-list-avatar src="{{currentLanguage.flagPath}}">
    </span>
  </button>
</div>