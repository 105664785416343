<div class="toolbar" id="header">
  
  <div class="container" fxLayout="row" fxLayoutAlign="space-between">

    <div class="sidenav-header" fxLayout="row" fxLayoutAlign="start center">
      <button fxShow fxHide.gt-sm class="menu-toggle-mobile" type="button" mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>

      <a fxHide fxShow.gt-xs [routerLink]="isDisabled() ? '/myprofile' : '/'" style="text-decoration: none;">
        <img class="logo" alt="logo" src="assets/img/nuvola_logo.png">
        <div fxFlex fxLayoutAlign="start" class="version">v{{version}}
          <div class="environment" *ngIf="environmentName!=''">{{environmentName}}</div>
        </div>
      </a>
    </div>


    <div fxLayout="row" fxLayoutGap="1px">
      
      <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>

      <elastic-toolbar-user-button>
      </elastic-toolbar-user-button>

    </div>
  </div>
</div>

