<div class='box disabled' *ngIf="isDisabled"></div>
<div *ngIf="!isDisabled">
    <div class='box' (click)="selectNextState()" *ngIf="currentState===CheckboxStateEnum.White"></div>
    <div style="display:flex"  *ngIf="currentState===CheckboxStateEnum.Red">
        <div (click)="selectNextState()" style="display:flex" class='box red'></div>
        <div (click)="selectNextTypeIpr()" [ngClass]="checkboxDisabled ? 'boxLabel-disabled' : 'boxLabel'">{{typeIPR}}</div>
    </div>
    <div style="display:flex"  *ngIf="currentState===CheckboxStateEnum.Green">
        <div (click)="selectNextState()" style="display:flex" class='box green'></div>
        <div (click)="selectNextTypeIpr()" [ngClass]="checkboxDisabled ? 'boxLabel-disabled' : 'boxLabel'">{{typeIPR}}</div>
    </div>
    <div style="display:flex"  *ngIf="currentState===CheckboxStateEnum.Blue">
        <div (click)="selectNextState()" style="display:flex" class='box blue'></div>
        <div (click)="selectNextTypeIpr()" [ngClass]="checkboxDisabled ? 'boxLabel-disabled' : 'boxLabel'">{{typeIPR}}</div>
    </div>
</div>