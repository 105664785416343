import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogOutConfirmModal, ToolbarUserButtonComponent } from './toolbar-user-button/toolbar-user-button.component';
import { ToolbarNotificationsComponent } from './toolbar-notifications/toolbar-notifications.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ToolbarAlphaComponent } from './toolbar-alpha/toolbar-alpha.component';
import { ToolbarBetaComponent } from './toolbar-beta/toolbar-beta.component';
import { SearchComponent } from './search/search.component';
import { ToolbarGammaComponent } from './toolbar-gamma/toolbar-gamma.component';
import { ToolbarNavigationComponent } from './toolbar-navigation/toolbar-navigation.component';
import { ToolbarNavigationItemComponent } from './toolbar-navigation/toolbar-navigation-item/toolbar-navigation-item.component';
import { ToolbarNavigationDropdownItemComponent } from './toolbar-navigation/toolbar-navigation-item/toolbar-navigation-dropdown-item/toolbar-navigation-dropdown-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../utils/utils.module';
import { ToolbarLanguageSelectorComponent } from './toolbar-language-selector/toolbar-language-selector.component';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from '../shared/shared.module';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { MatBadgeModule, MatCardModule, MatDialogModule, MatFormFieldModule, MatProgressSpinnerModule } from '@angular/material';
import { CreateUpdateUserModule } from '../../pages/components/create-update-user/create-update-user.module';
import { CreateUpdateUserPhotoModule } from 'src/app/pages/components/create-update-user-photo/create-update-user-photo.module';

// export const loader = ["en", "it"].reduce((acc, lang) => {
//   acc[lang] = () => import(`../../../assets/i18n/toolbar/${lang}.json`);
//   return acc;
// },{});

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    ScrollbarModule,
    MatInputModule,
    MatMenuModule,
    UtilsModule,
    MatRippleModule,
    MatListModule,
    SharedModule,
    TranslocoModule,
    MatCardModule,
    MatDialogModule,
    CreateUpdateUserModule,
    CreateUpdateUserPhotoModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatBadgeModule
  ],
  declarations: [
    ToolbarUserButtonComponent,
    ToolbarNotificationsComponent,
    SearchBarComponent,
    ToolbarAlphaComponent,
    ToolbarBetaComponent,
    SearchComponent,
    ToolbarGammaComponent,
    ToolbarNavigationComponent,
    ToolbarNavigationItemComponent,
    ToolbarNavigationDropdownItemComponent,
    ToolbarLanguageSelectorComponent,
    LogOutConfirmModal
  ],
  exports: [
    ToolbarUserButtonComponent,
    ToolbarNotificationsComponent,
    SearchBarComponent,
    ToolbarAlphaComponent,
    ToolbarBetaComponent,
    SearchComponent,
    ToolbarGammaComponent,
    ToolbarNavigationComponent,
    ToolbarNavigationItemComponent,
    ToolbarNavigationDropdownItemComponent,
    ToolbarLanguageSelectorComponent
  ],
  providers:[
    {
      provide:TRANSLOCO_SCOPE, useValue:{scope:'toolbar'}
    }
  ],
  entryComponents:[
    LogOutConfirmModal,
  ]
})
export class ToolbarModule { }
