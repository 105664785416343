<div fxLayout="row" fxLayoutAlign="center center" style="height: 100%;" >
  <div class="register mat-elevation-z12" >
    <div class="register-header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">
      <img class="logo" src="assets/img/nuvola_logo_white.png">
      <h5 class="panel-title">NuvolaWeb 4.0</h5>
      <nuvolaweb-toolbar-language-selector></nuvolaweb-toolbar-language-selector>
    </div>
    <form [formGroup]="ngForm" (ngSubmit)="send()" *ngIf="checked && !isPreloadEnd && !isLoading">
      <div class="register-content" fxLayout="column" fxLayoutAlign="start stretch">
        <mat-form-field>
          <input matInput placeholder="{{'RESET.PasswordPlaceHolder' | transloco}}" [type]="hide? 'password' : 'text'" name="password" required formControlName="password" >
          <button mat-icon-button matSuffix (click)="hide = !hide" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="{{'RESET.PasswordConfirmPlaceHolder' | transloco}}" [type]="hideConfirm? 'password' : 'text'" name="password-confirm" required formControlName="passwordConfirm">
          <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" type="button" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div class="extra-options" fxLayout="row" fxLayoutAlign="center center">
          <small>{{'RESET.InfoText' | transloco}}</small>
        </div>
        <button color="primary" mat-raised-button [disabled]="!ngForm.valid">{{'RESET.ResetButton' | transloco}}</button>
       <p class="note">
        <a [routerLink]="['/']">{{'RESET.LogInLink' | transloco}}</a>
       </p> 
      </div>
    </form>
    <div class="error-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="!checked || (!success && isPreloadEnd)">
      <p class="errorMessage">{{'RESET.errorMessage' | transloco}}</p>
      <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
    </div>
    <div class="error-content" fxLayout="column" fxLayoutAlign="start stretch" *ngIf="success && isPreloadEnd">
      <p class="successMessage">{{'RESET.successMessage' | transloco}}</p>
      <p class="note"><a [routerLink]="['/']">{{'FORGOT.BackLink' | transloco}}</a></p>
    </div>
    <div *ngIf="isLoading"
    fxLayout="row" fxLayoutAlign="center center"
    >
      <mat-progress-spinner
      class="margin"
      [mode]="'indeterminate'"
      [color]="'accent'"
      [value]="10"
      >
      </mat-progress-spinner>
    </div>
  </div>
</div>
  