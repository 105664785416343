import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebviewerComponent } from './webviewer.component';
import { MatButtonModule, MatCardModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatSelectModule, MatSlideToggleModule, MatTabsModule, MatTooltipModule } from '@angular/material';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TreatmentChatModule } from '../../new-patient/treatment-additional-functional/treatment-chat/treatment-chat.module';
import { ScrollbarModule } from 'src/app/core/scrollbar/scrollbar.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { ChatModule } from '../../chat/chat.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LowerArchTableModule } from '../../new-patient/treatment-additional-functional/treatment-ipr/lower-arch-table/lower-arch-table.module';
import { UpperArchTableModule } from '../../new-patient/treatment-additional-functional/treatment-ipr/upper-arch-table/upper-arch-table.module';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [WebviewerComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    TranslocoModule,
    IvyCarouselModule,
    FlexLayoutModule,
    TreatmentChatModule,
    ScrollbarModule,
    ChatModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    DragDropModule,
    LowerArchTableModule,
    UpperArchTableModule
  ],
  exports:[WebviewerComponent],
  providers:[
    {provide:TRANSLOCO_SCOPE, useValue:{scope:"patient"}}
  ]
})
export class WebviewerModule { }
