<div class="card alternative group no-border mat-elevation-z2" fxLayout="column" fxLayoutGap="24px">
  <div class="group-title">Material Slider</div>
  <div class="divider reverse"></div>
  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start" fxLayoutGap="16px" fxLayoutGap.gt-md="24px">
    <div fxFlex.gt-md>
      <div fxLayout="column">
        <div class="section-title">Slider with Thumb Label and Ticks</div>
        <div class="section-tag">&lt;mat-slider&gt;</div>
        <p>Sliders allow for selection of a value from a range via mouse, touch, or keyboard.</p>
        <div class="section-info">Example:</div>
        <p><code vrHighlight class="html">&lt;mat-slider<br/>  min="1"<br/>  max="5"<br/>  step="0.5"<br/> value="1.5"<br/>&gt;&lt;/mat-slider&gt;</code></p>
      </div>
    </div>
    <mat-tab-group class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%">
      <mat-tab label="PREVIEW">
        <div class="preview" fxLayout="row" fxLayoutAlign="center center">
          <mat-card fxFlex="80%">
            <mat-card-content>
              <mat-slider style="width: 100%;" min="1" max="10" thumbLabel tickInterval="1" value="7"></mat-slider>
              <mat-slider color="primary" style="width: 100%;" min="1" max="10" value="2"></mat-slider>
              <mat-slider color="warn" style="width: 100%;" min="1" max="10" value="8"></mat-slider>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="HTML">
        <code [innerHTML]="slider1HTML" vrHighlight class="html"></code>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="divider reverse"></div>

  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start" fxLayoutGap="16px" fxLayoutGap.gt-md="24px">
    <div fxFlex.gt-md fxLayout="column">
      <div class="section-title">Vertical Slider with Thumb-Label</div>
      <mat-tab-group class="preview-tabs mat-elevation-z2">
        <mat-tab label="PREVIEW">
          <div class="preview" fxLayout="row" fxLayoutAlign="center center">
            <mat-card fxFlex="80%">
              <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                <mat-slider vertical min="1" max="10" thumbLabel tickInterval="1" value="4"></mat-slider>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab label="HTML">
          <code [innerHTML]="slider2HTML" vrHighlight class="html"></code>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div fxFlex.gt-md fxLayout="column">
      <div class="section-title">Simple Slider without Thumb-Label and Ticks</div>
      <div class="preview-tabs mat-elevation-z2">
        <mat-tab-group>
          <mat-tab label="PREVIEW">
            <div class="preview" fxLayout="row" fxLayoutAlign="center center">
              <mat-card fxFlex="80%">
                <mat-card-content fxLayout="row" fxLayoutAlign="center center">
                  <mat-slider min="1" max="10" value="7"></mat-slider>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-tab>
          <mat-tab label="HTML">
            <code [innerHTML]="slider3HTML" vrHighlight class="html"></code>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
