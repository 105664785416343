import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NewPatientComponent } from '../new-patient/new-patient/new-patient.component';
import { TreatmentAdditionalFunctionalComponent } from '../new-patient/treatment-additional-functional/treatment-additional-functional.component';
import { TreatmentModalComponent } from '../new-patient/treatment-modal/treatment-modal.component';
import { TreatmentsNextPhaseComponent } from '../treatments-next-phase/treatments-next-phase.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<any> {

  constructor() { }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {
    console.log('CanDeactivateGuard = ', component);
    if (component instanceof TreatmentsNextPhaseComponent) {
      console.log('ignoreModal', component.ignoreModal);
      if (!component.ignoreModal) {
        return component.openExitWithoutSaveModal();
      }
    } else if (component instanceof NewPatientComponent) {
      //console.log('NewPatientComponent');
      if (component.currentUser && component.currentUser.role == 'DO' &&
        component.selectedTreatment && component.selectedTreatment.status == 'DRAFT' &&
        (component.selectedTreatment.step1Complete && component.selectedTreatment.step2Complete &&
          component.selectedTreatment.step3Complete && component.selectedTreatment.step4Complete &&
          component.selectedTreatment.step5Complete && component.selectedTreatment.step6Complete)) {
        return component.openCheckSendToNuvolaModal('sendToNuvola');
      }
      else if (component.currentUser && component.currentUser.userGroup == 'PJ' && !component.treatmentPlanAllMandatoryFieldsCompleted &&
        component.selectedTreatment && component.selectedTreatment.status == 'INPROJECT' && !component.treatmentPlanAllMandatoryFieldsCompleted) {
        console.log('check for PJ');
        return component.openCheckSendToNuvolaModal('savePlan');
      }
    }
    return true;
  }

}
