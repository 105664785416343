import * as tslib_1 from "tslib";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(_authService, router) {
        this._authService = _authService;
        this.router = router;
    }
    canActivate(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this._authService.isLoggedIn()) {
                //console.log(this._authService.getExpireTokenTime());
                if (this._authService.isTokenExpired()) {
                    return (yield this._authService.refreshTokenTrigger()) ? true : this.router.navigate(["/"], { queryParams: { returnUrl: state.url } });
                }
                else {
                    return true;
                }
            }
            else {
                this.router.navigate(["/"], { queryParams: { returnUrl: state.url } });
            }
        });
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
