import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TranslocoService } from '@ngneat/transloco';
import { User } from '../../../modules/security/models/user.model';
import { UserService } from '../../../modules/security/services/user.service';
import { ContentsService } from '../../contents/services/contents.service';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'nuvolaweb-create-update-user-photo',
  templateUrl: './create-update-user-photo.component.html',
  styleUrls: ['./create-update-user-photo.component.scss']
})
export class CreateUpdateUserPhotoComponent implements OnInit {
  @Output() createUpdatePhoto: EventEmitter<any> = new EventEmitter();
  
  public photoForm:FormGroup
  public currentUser:User;
  public isEditLoading:boolean;

  constructor(
    private userService:UserService,
    private usersService:UsersService,
    private contentsService:ContentsService,
    private cd:ChangeDetectorRef,
    private fb: FormBuilder,
    private _translocoService:TranslocoService,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit() {
    this.currentUser = this.userService.readUserFromLocalStorage();
    
    this.photoForm = this.fb.group({
      file:[null]
    });

    if(this.currentUser.photoFileName){
      this.photoForm.controls.file.setValue({"formData":null, "fileName":this.currentUser.photoFileName, "photoUrl":null});
    }
  }

  closeModal(){
    this.createUpdatePhoto.emit({"updated":false, "fileName":null, "photoUrl":null, "closeModal":true});
  }

  fileDroppedHandle(event){
    this.photoForm.controls.file.setValue(event);
  }

  save(){
    this.isEditLoading = true;
    this.contentsService.uploadContentBlob(this.photoForm.controls.file.value.formData, "users-photos", true).subscribe((resp:any) => {
      if(resp){
        this.usersService.updateUserPhoto(this.currentUser.idAdmin, this.photoForm.controls.file.value.fileName).subscribe((response:any)=> {
          this.createUpdatePhoto.emit({"updated":true, "fileName":this.photoForm.controls.file.value.fileName});
          this.isEditLoading = false;
        })
        this.snackBar.open(this._translocoService.translate("COMMON.updatePhotoSuccessMessage"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"success",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }else{
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }
    });
  }

  delete(){
    if(!this.currentUser.photoFullUrl){
      this.photoForm.reset();
      return;
    }
    this.isEditLoading = true;
    this.contentsService.deleteContentFile("users-photos", this.photoForm.controls.file.value.fileName).subscribe(resp => {
      if(resp){
        this.usersService.updateUserPhoto(this.currentUser.idAdmin, null).subscribe((response:any)=> {
          this.createUpdatePhoto.emit({"updated":true, "fileName":null});
          this.isEditLoading = false;
          this.currentUser.photoFullUrl = null;
          this.currentUser.photoFileName = null;
          this.photoForm.reset();
        })
        this.snackBar.open(this._translocoService.translate("COMMON.deletePhotoSuccessMessage"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"success",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }else{
        this.snackBar.open(this._translocoService.translate("COMMON.ErrorMessage"), "X", {
          direction:"rtl",
          duration:3000,
          panelClass:"warning",
          horizontalPosition:"right",
          verticalPosition:"top",
        } as MatSnackBarConfig);
      }
      this.cd.detectChanges();
    });
  }

}
