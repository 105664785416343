import { Translation, TranslocoLoader, translocoConfig } from '@ngneat/transloco';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TranslocoHttpLoader {
    constructor(http) {
        this.http = http;
    }
    getTranslation(lang) {
        return this.http.get(`/assets/i18n/${lang}.json`);
    }
}
TranslocoHttpLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslocoHttpLoader_Factory() { return new TranslocoHttpLoader(i0.ɵɵinject(i1.HttpClient)); }, token: TranslocoHttpLoader, providedIn: "root" });
const ɵ0 = translocoConfig({
    availableLangs: [
        { id: "en", label: "English" },
        { id: "it", label: "Italian" },
    ],
    defaultLang: 'en',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production,
});
export class TranslocoRootModule {
}
export { ɵ0 };
