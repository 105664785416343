import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteHandlerComponent } from './route-handler.component';
import { RouterModule } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [RouteHandlerComponent],
  exports: [RouteHandlerComponent],
  providers:[
    {
      provide:TRANSLOCO_SCOPE,
      useValue:{scope:"toolbar"}
    }
  ]
})
export class RouteHandlerModule { }
