import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'nuvolaweb-registration-confirmation',
  templateUrl: './registration-confirmation.component.html',
  styleUrls: ['./registration-confirmation.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class RegistrationConfirmationComponent implements OnInit {

  public token:string;
  isSuccess: boolean = false;
  public isLoading:boolean;

  constructor(
    private activeRoute:ActivatedRoute,
    private usersService:UsersService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.token = this.activeRoute.snapshot.params.token;
    //console.log(this.token);

    if(this.token){
      this.isLoading = true;
      this.usersService.confirmEmail(this.token).subscribe( (resp:any)  => {
        console.log('resp = ', resp);      
        const userLang = resp.language;
        this.translocoService.setActiveLang(userLang);
        if(resp.result){
          this.isSuccess = true;
        }
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });
    }
  }

}
