import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
export class RegisterComponent {
    constructor(router, fb, _usersService, snackBar, _translocoService, _cb) {
        this.router = router;
        this.fb = fb;
        this._usersService = _usersService;
        this.snackBar = snackBar;
        this._translocoService = _translocoService;
        this._cb = _cb;
        this.showHowManyPatients = false;
        this.hide = true;
        this.autocompleteEndpoint = "/api/Country/AutoComplete";
        this.agents = [];
        this.isLoading = false;
        this.siteKey = environment.recaptchaSiteKeyClient;
        this.isUsaVersion = environment.isUsaVersion;
    }
    ngOnInit() {
        this.addressesForm = this.fb.group({
            phone: ["", [Validators.maxLength(30)]],
            address: [null, [Validators.required, Validators.maxLength(255)]],
            zipCode: [null, [Validators.required, Validators.maxLength(12)]],
            //countryCode:[null, Validators.required],
            countryCode: [null, Validators.required],
            //countryName:[null, Validators.required],
            activityName: [null, [Validators.maxLength(100)]],
            city: [null, Validators.required]
        });
        this.registerForm = this.fb.group({
            firstName: [null, [Validators.required, Validators.maxLength(50)]],
            lastName: [null, [Validators.required, Validators.maxLength(50)]],
            addressType: ["R"],
            vat: [null, [Validators.required, Validators.maxLength(20)]],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,16}$/)]],
            captcha: [null, [Validators.required]],
            active: [true],
            executiveArea: [""],
            idSuperAgent: [0],
            idDistributor: [0],
            userGroup: [""],
            idAgent: [0],
            language: ['en'],
            question1: [false],
            // question2: [false],
            // question3: [false],
            question11: [null]
        });
        setTimeout(() => {
            this.registerForm.controls.addressType.setValue("R");
            this._cb.detectChanges();
        }, 350);
    }
    // selectCounty(country){
    //   this.addressesForm.controls.countryCode.setValue(country.key);
    //   this.addressesForm.controls.countryName.setValue(country.value);
    // }
    // clearSelected(){
    //   this.addressesForm.controls.countryCode.reset()
    //   this.addressesForm.controls.countryName.reset();
    // }
    register() {
        if (!this.registerForm.valid || !this.addressesForm.valid) {
            this.registerForm.controls.firstName.markAsTouched({ onlySelf: true });
            this.registerForm.controls.lastName.markAsTouched({ onlySelf: true });
            this.registerForm.controls.vat.markAsTouched({ onlySelf: true });
            this.registerForm.controls.email.markAsTouched({ onlySelf: true });
            this.registerForm.controls.password.markAsTouched({ onlySelf: true });
            this.registerForm.controls.captcha.markAsTouched({ onlySelf: true });
            this.addressesForm.controls.address.markAsTouched({ onlySelf: true });
            this.addressesForm.controls.zipCode.markAsTouched({ onlySelf: true });
            this.addressesForm.controls.countryCode.markAsTouched({ onlySelf: true });
            //this.addressesForm.controls.countryName.markAsTouched({onlySelf:true});
            this.addressesForm.controls.activityName.markAsTouched({ onlySelf: true });
            this.addressesForm.controls.city.markAsTouched({ onlySelf: true });
            //this.countryField.filterControl.markAsTouched({onlySelf:true});
            return;
        }
        this.isLoading = true;
        let lang = this._translocoService.getActiveLang();
        var request = Object.assign({}, this.registerForm.value, { isPublicRegistration: true, addresses: [
                Object.assign({ addressType: "R" }, this.addressesForm.value),
                Object.assign({ addressType: "S" }, this.addressesForm.value),
                Object.assign({ addressType: "I" }, this.addressesForm.value, { "vat": this.registerForm.controls.vat.value })
            ], role: "DO", language: lang, question1: this.registerForm.controls.question1.value ? 'Y' : 'N', question11: this.registerForm.controls.question11.value == null ? 'N' : this.registerForm.controls.question11.value });
        this._usersService.createDoctor(request).subscribe(resp => {
            this.router.navigate(["/confirm-registration"]);
            this.snackBar.open(this._translocoService.translate("REGISTRATION.successMessage"), "X", {
                duration: 3000,
                panelClass: "success",
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.isLoading = false;
        }, (error) => {
            if (error.status == 409) {
                this.snackBar.open(this._translocoService.translate("REGISTRATION.errorMessageAlreadyExists"), "X", {
                    duration: 3000,
                    panelClass: "warning",
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
            }
            else {
                this.snackBar.open(this._translocoService.translate("REGISTRATION.errorMessage"), "X", {
                    duration: 3000,
                    panelClass: "warning",
                    horizontalPosition: "right",
                    verticalPosition: "top",
                });
            }
            this.isLoading = false;
        });
    }
    resolved(captchaResponse) {
        console.log(captchaResponse);
        this.registerForm.controls.captcha.setValue(captchaResponse);
        this.registerForm.controls.captcha.markAsTouched({ onlySelf: true });
    }
    errored(capthaError) {
        console.warn(`reCAPTCHA error encountered: ${capthaError}`);
    }
    onZipCodeInput(event) {
        var currentValue = "";
        if (this.registerForm.controls.zipCode) {
            currentValue = this.registerForm.controls.zipCode.value;
            this.registerForm.controls.zipCode.reset();
            this.registerForm.controls.zipCode.setValue(currentValue.replace(/[^0-9.]/g, ""));
        }
    }
    isButtonDisabled() {
        var result = true;
        if (this.registerForm.valid && this.addressesForm.valid) {
            result = false;
        }
        return result;
    }
    toggleAligners(event) {
        this.showHowManyPatients = event.checked;
        if (event.checked) {
            this.registerForm.controls.question11.setValidators(Validators.required);
        }
        else {
            this.registerForm.controls.question11.clearValidators();
        }
    }
}
