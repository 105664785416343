import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, pairwise } from 'rxjs/operators';
import { TreatmentAdditionalFunctionalService } from '../../treatment-additional-functional.service';
import { CheckboxState, TypeIpr } from '../../../../../core/enums';

@Component({
  selector: 'nuvolaweb-upper-arch-table',
  templateUrl: './upper-arch-table.component.html',
  styleUrls: ['./upper-arch-table.component.scss']
})
export class UpperArchTableComponent implements OnInit, OnDestroy {

  @Input("upperArch_1") upperArch_1: any;
  @Input("upperArch_2") upperArch_2: any;
  @Input("upperArch_3") upperArch_3: any;

  @Input("forceDisable") forceDisable: boolean = false;
  @Input("hideTitle") hideTitle: boolean = false;

  private _subscribers: Subscription

  upperTeeth: any[];

  public upperArch_1Form: FormGroup;
  public upperArch_2Form: FormGroup;
  public upperArch_3Form: FormGroup;

  public allValues = ['', 'All 1', 'All 2', 'All 3', 'All 4', 'All 5', 'All 6', 'All 7', 'All 8', 'All 9', 'All 10', 'All 11', 'All 12',
                      'All 13', 'All 14', 'All 15', 'All 16', 'All 17', 'All 18', 'All 19', 'All 20', 'All 21', 'All 22', 'All 23', 'All 24', ];

  private isDisabled1 = false;
  private isDisabled2 = false;
  private isDisabled3 = false;

  @ViewChild('boxes1', { static: true }) checkboxes1;

  constructor(
    private fb: FormBuilder,
    private treatmentAdditionalFunctionalService: TreatmentAdditionalFunctionalService,
  ) { }

  ngOnInit() {
    this.prepareTeeth();

    let _initForm = () => {
      return this.fb.group({
        all: 0,
        col1: '',
        col2: '',
        col3: [""],
        col4: [""],
        col5: [""],
        col6: [""],
        col7: [""],
        col8: [""],
        col9: [""],
        col10: [""],
        col11: [""],
        col12: [""],
        col13: [""],
        col14: [""],
        col15: [""]
      });
    };

    [1, 2, 3].map((row) => {
      this[`upperArch_${row}Form`] = _initForm();
    });

    if (this.treatmentAdditionalFunctionalService.getIsTabContentDisabledValue()) {
      // this.upperArch_1Form.disable();
      // this.upperArch_2Form.disable();
      // this.upperArch_3Form.disable();
      this.upperArch_1Form.controls['all'].disable();
      this.upperArch_2Form.controls['all'].disable();
      this.upperArch_3Form.controls['all'].disable();
    }

    this._subscribers = this.treatmentAdditionalFunctionalService.isTabsContentDisabled.subscribe(value => {
      // if(value){
      //   this.upperArch_1Form.disable();
      //   this.upperArch_2Form.disable();
      //   this.upperArch_3Form.disable();
      // }else{
      //   this.upperArch_1Form.enable();
      //   this.upperArch_2Form.enable();
      //   this.upperArch_3Form.enable();
      // }
      if (value) {
        this.upperArch_1Form.controls['all'].disable();
        this.upperArch_2Form.controls['all'].disable();
        this.upperArch_3Form.controls['all'].disable();
      } else {
        this.upperArch_1Form.controls['all'].enable();
        this.upperArch_2Form.controls['all'].enable();
        this.upperArch_3Form.controls['all'].enable();
      }
    })

    if (this.forceDisable) {
      this.upperArch_1Form.controls['all'].disable();
      this.upperArch_2Form.controls['all'].disable();
      this.upperArch_3Form.controls['all'].disable();
    }

  }

  ngAfterViewInit(): void {
    this.initForms();

    [1, 2, 3].map((row) => {
      this[`upperArch_${row}Form`].valueChanges.pipe().subscribe(value => {
        for (let prop in value) {
          if (prop == "all") {
            if (value[prop] != this[`upperArch_${row}`][prop]) {
              this[`upperArch_${row}`][prop] = (value[prop] as number);
              this[`upperArch_${row}Form`].controls[prop].setValue(value[prop]);

              if (this[`upperArch_${row}`][prop] == 0) {
                this.disableRow(row);
                this.resetRowCheckboxes(row);
              }
              if (this[`upperArch_${row}`][prop] > 0) {
                this.enableRow(row);
              }
            }
          } else {
            if (value[prop].currentState != this[`upperArch_${row}`][prop + 'Color']) {
              console.log('fire');
              this[`upperArch_${row}`][prop + 'Color'] = (value[prop].currentState);
              this[`upperArch_${row}Form`].controls[prop].setValue(value[prop]);

            }
            if (value[prop].typeIPR != this[`upperArch_${row}`][prop + 'Value']) {
              this[`upperArch_${row}`][prop + 'Value'] = (value[prop].typeIPR);
              this[`upperArch_${row}Form`].controls[prop].setValue(value[prop]);

            }
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this._subscribers.unsubscribe();
  }

  resetRowCheckboxes(row: number) {
    let contextValue = {
      currentState: CheckboxState.White,
      typeIPR: TypeIpr.None
    };


    for (let controlName in this[`upperArch_${row}Form`].controls) {
      if (!this[`upperArch_${row}`].hasOwnProperty(controlName)) {
        this[`upperArch_${row}`][controlName] = contextValue;
      }
    }
  }

  disableRow(row: number) {

    for (let index = 1; index <= 15; index++) {
      this[`upperArch_${row}Form`].get('col' + index).disable();
    }
  }

  enableRow(row: number) {
    this.setRowData(row);
    for (let index = 1; index <= 15; index++) {
      this[`upperArch_${row}Form`].get('col' + index).enable();
    }
  }

  setRowData(row: number) {
    for (let controlName in this[`upperArch_${row}Form`].controls) {
      var contextValue: any = '';
      if (this[`upperArch_${row}`].hasOwnProperty(controlName)) {
        contextValue = this[`upperArch_${row}`][controlName];
        if (contextValue == 0) {
          this.disableRow(row);
        }
      } else {
        contextValue = {
          currentState: this[`upperArch_${row}`][`${controlName}Color`],
          typeIPR: this[`upperArch_${row}`][`${controlName}Value`]
        };
      }
      this[`upperArch_${row}Form`].controls[controlName].setValue(contextValue);
    }
  }

  initForms() {
    for (let row = 1; row <= 3; row++) {
      this.setRowData(row);
    }
  }

  public getColName(index: number): string {
    return `col${index + 1}`;
  }

  prepareTeeth() {
    this.upperTeeth = [];
    this.upperTeeth.push(
      { id: "1,8", src: "../../../../../assets/img/teeth/1,8.png", style: "13px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,7", src: "../../../../../assets/img/teeth/1,7.png", style: "13px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,6", src: "../../../../../assets/img/teeth/1,6.png", style: "13px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,5", src: "../../../../../assets/img/teeth/1,5.png", style: "11px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,4", src: "../../../../../assets/img/teeth/1,4.png", style: "10px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,3", src: "../../../../../assets/img/teeth/1,3.png", style: "8px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,2", src: "../../../../../assets/img/teeth/1,2.png", style: "5px", isSelected: false, icon: "", selectedValue: "" },
      { id: "1,1", src: "../../../../../assets/img/teeth/1,1.png", style: "8px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,1", src: "../../../../../assets/img/teeth/2,1.png", style: "9px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,2", src: "../../../../../assets/img/teeth/2,2.png", style: "5px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,3", src: "../../../../../assets/img/teeth/2,3.png", style: "8px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,4", src: "../../../../../assets/img/teeth/2,4.png", style: "10px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,5", src: "../../../../../assets/img/teeth/2,5.png", style: "11px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,6", src: "../../../../../assets/img/teeth/2,6.png", style: "13px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,7", src: "../../../../../assets/img/teeth/2,7.png", style: "14px", isSelected: false, icon: "", selectedValue: "" },
      { id: "2,8", src: "../../../../../assets/img/teeth/2,8.png", style: "13px", isSelected: false, icon: "", selectedValue: "" }
    );
  }

}
