<div class="autocomplete group card alternative no-border mat-elevation-z2" fxLayout="column" fxLayoutGap="24px">
  <div class="group-title">Material Snack-Bar</div>
  <div class="divider reverse"></div>
  <div class="section" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start stretch" fxLayoutAlign.gt-md="start start" fxLayoutGap="16px" fxLayoutGap.gt-md="24px">
    <div fxFlex.gt-md>
      <div fxLayout="column">
        <div class="section-title">Snack-Bar Notification</div>
        <p>Snack-Bars are a great way to display notifications to users or to give the user the status on something.</p>
        <code [innerHTML]="snackbarTS" vrHighlight class="typescript"></code>
      </div>
    </div>
    <mat-tab-group class="preview-tabs mat-elevation-z2" fxFlex="auto" fxFlex.gt-md="70%">
      <mat-tab label="PREVIEW">
        <div class="preview" fxLayout="row" fxLayoutAlign="center center">
          <mat-card fxFlex="90%" fxFlex.gt-sm="80%">
            <mat-card-content fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="space-around center">
              <button mat-button (click)="openSnackbar()">Trigger Snackbar</button>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="HTML">
        <code [innerHTML]="snackbarHTML" vrHighlight class="html"></code>
      </mat-tab>
      <mat-tab label="TS">
        <code [innerHTML]="snackbarTS" vrHighlight class="typescript"></code>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
