import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { version } from "../../package.json";

@Component({
  selector: 'elastic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {

  constructor(
    private translocoService:TranslocoService,
    private titelService:Title
    ) {
  }

  ngOnInit(){
    var preferedLanguage = localStorage.getItem("nuvolaLanguage");
    if(!preferedLanguage){
      preferedLanguage = this.translocoService.getActiveLang();
      localStorage.setItem("nuvolaLanguage", preferedLanguage);
    }
    this.translocoService.setActiveLang(preferedLanguage);

    var currentTilte = this.titelService.getTitle();
    this.titelService.setTitle(`${currentTilte} - v. ${version}`)
  }
}
