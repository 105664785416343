<div class="content" fxFlex fxLayoutGap="25px" fxLayoutAlign="center" fxLayout="column" style="margin-bottom: 40px">

    <div *ngIf="!hideTitle">
        <h5>{{'patient.ADDITIONAL.upperArchLabel' | transloco}}</h5>
    </div>

    <div style="display: flex; flex-direction: column;">
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin-left: 0px;">
            <div style="width: 80px;height: 73px;position: relative;">
                <div style="position: absolute;bottom: 0;left: 0;">{{'patient.ADDITIONAL.upperArchBeforeOfLabel' | transloco}}</div>
            </div>
            <div *ngFor="let tooth of upperTeeth" fxLayout='column' class="tooth-item" [style.backgroundImage]="'url('+tooth.src+')'"
            style="height: 73px; width: 50px; background-size: auto 92%; background-position: center top; background-repeat: no-repeat;">
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 30px 0 0;">
            <form [formGroup]="upperArch_1Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            
            <div *ngFor="let tooth of upperTeeth.slice(0, upperTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="upperArch_1Form" style="width: 50px;">
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="upperArch_1Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 20px 0 0;">
            <form [formGroup]="upperArch_2Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            <div *ngFor="let tooth of upperTeeth.slice(0, upperTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="upperArch_2Form" style="width: 50px;">
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="upperArch_2Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
        <div fxFlex fxLayout="column" fxLayout.gt-sm="row" class="teeth-line" style="margin: 20px 0 0;">
            <form [formGroup]="upperArch_3Form">
                <select style="width: 90px; margin-right: 30px;height: 30px;" formControlName="all">
                    <option *ngFor="let all of allValues; let i = index" [ngValue]="i">{{all}}</option>
                </select>
            </form>
            <div *ngFor="let tooth of upperTeeth.slice(0, upperTeeth.length - 1); let i = index" 
            fxLayout='column' 
            class="tooth-item">
                <form [formGroup]="upperArch_3Form" style="width: 50px;">
                    <!-- <input maxlength="5" style="width: 35px;" formControlName="col1" type="text"> -->
                    <nuvolaweb-multi-select-checkbox [formControlName]="getColName(i)" [checkboxDisabled]="upperArch_3Form.controls['all'].disabled"></nuvolaweb-multi-select-checkbox>
                </form>
            </div>
        </div>
    
    </div>
</div>