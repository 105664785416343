import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgModule } from '@angular/core';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WebviewerComponent } from './webviewer/webviewer.component';
import { RegistrationConfirmationComponent } from './registration-confirmation/registration-confirmation.component';

const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch:"full"
  },
  {
    path:"",
    component:LoginComponent,
    pathMatch:"full"
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch:"full"
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch:"full"
  },
  {
    path:"session_expired",
    component:SessionExpiredComponent,
    pathMatch:"full"
  },
  {
    path:"reset-password",
    component:ResetPasswordComponent,
  },
  {
    path:"reset-password/:code",
    component:ResetPasswordComponent,
  },
  {
    path:"change-password",
    component:ChangePasswordComponent,
    pathMatch:"full"
  },
  {
    path:"webviewer",
    component:WebviewerComponent,
    pathMatch:"full"
  },
  {
    path:"confirm-registration/:token",
    component:RegistrationConfirmationComponent,
  },
  {
    path:"confirm-registration",
    component:RegistrationConfirmationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})

export class AuthRoutes {}