
  <mat-card-content fxLayout="column" fxLayoutAlign="center center">
    <span>
      {{'toolbar.USERCOMPONENT.confirmMessage' | transloco}}
    </span>
  </mat-card-content>
  <mat-card-actions >
    <div fxLayout="row" style="padding-top:25px;">
      <button mat-raised-button class="btn-default" (click)="logOut()">
        {{'toolbar.USERCOMPONENT.confirmButton' | transloco}}
      </button>
      <span fxFlex></span>
      <button mat-raised-button color="warn" (click)="dialogRef.close()">
        {{'toolbar.USERCOMPONENT.declineButton' | transloco}}
      </button>
    </div>
  </mat-card-actions>
  