import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside.directive';
import { HighlightDirective } from './highlight.directive';
import { CountUpModule } from 'countup.js-angular2';
import { OnlyNumberDirective } from './only-number.directive';
import { PriceInputDirective } from './priceInput.directive';

@NgModule({
  imports: [
    CommonModule,
    CountUpModule
  ],
  declarations: [
    ClickOutsideDirective,
    HighlightDirective,
    OnlyNumberDirective,
    PriceInputDirective,
  ],
  exports: [
    ClickOutsideDirective,
    HighlightDirective,
    CountUpModule,
    PriceInputDirective
  ]
})
export class UtilsModule { }
