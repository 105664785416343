import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'nuvolaweb-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class SessionExpiredComponent implements OnInit {
  
  constructor(
    private authService:AuthenticationService,
  ) { }

  ngOnInit() {
    this.authService.clearStorage();
  }

}
