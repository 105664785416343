const downloadFile = (resp) => {
  return new Promise(resolve => {
    const downloadURL = window.URL.createObjectURL(resp.body);
    const link = document.createElement('a');
    link.href = downloadURL;
    let fileName = resp.headers.get('content-disposition').split(';')[1].trim().split('=')[1];
    if (fileName.slice(0, 1) === '"' && fileName.slice(fileName.length - 1, fileName.length) === '"') {
      fileName = fileName.substring(1, fileName.length - 1);
    }
    link.download = fileName;
    link.click();
    setTimeout(() => {
      link.remove();
      resolve();
    }, 100);
  });
};

const objectToFormData = (obj) => {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]: any) => {
    formData.append(key, value);
  });

  return formData;
};

export {
  downloadFile,
  objectToFormData,
};
