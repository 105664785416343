import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessControlDirective } from './directives/access-control.directive';
import { SafePipe } from './pipes/safe.pipe';
import { AllArchPipe } from './pipes/allArch.pipe';
import { DragDirective } from './directives/drag-and-drop-file.directive';
import { ShortDatePipe } from './pipes/shortDate.pipe';
import { LongDatePipe } from './pipes/longDate.pipe';



@NgModule({
  declarations: [
  AccessControlDirective,
  SafePipe,
  DragDirective,
  LongDatePipe,
  ShortDatePipe,
  AllArchPipe
],
  imports: [
    CommonModule
  ],
  exports: [
    AccessControlDirective,
    DragDirective,
    LongDatePipe,
    ShortDatePipe,
    SafePipe,
    AllArchPipe
  ]
})
export class SharedModule { }
