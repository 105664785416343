/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-header.component";
var styles_PageHeaderComponent = [i0.styles];
var RenderType_PageHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageHeaderComponent, data: {} });
export { RenderType_PageHeaderComponent as RenderType_PageHeaderComponent };
export function View_PageHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "page-header"]], [[4, "background-image", null], [4, "height", null], [2, "reverse-padding", null], [2, "reverse-padding-top", null], [2, "reverse-padding-right", null], [2, "reverse-padding-bottom", null], [2, "reverse-padding-left", null], [4, "margin-bottom", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.background; var currVal_1 = _co.height; var currVal_2 = _co.reverse; var currVal_3 = _co.reverseDir[0]; var currVal_4 = _co.reverseDir[1]; var currVal_5 = _co.reverseDir[2]; var currVal_6 = _co.reverseDir[3]; var currVal_7 = _co.margin; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_PageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-page-header", [], null, null, null, View_PageHeaderComponent_0, RenderType_PageHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageHeaderComponentNgFactory = i1.ɵccf("elastic-page-header", i2.PageHeaderComponent, View_PageHeaderComponent_Host_0, { height: "height", background: "background", reverse: "reverse", reverseDirection: "reverseDirection" }, {}, []);
export { PageHeaderComponentNgFactory as PageHeaderComponentNgFactory };
