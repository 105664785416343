import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentChatComponent } from './treatment-chat.component';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from '../../../../core/utils/utils.module';
import { MatButtonModule, MatCard, MatCardModule, MatDialogModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatTableModule, MatToolbarModule } from '@angular/material';
import { ScrollbarModule } from '../../../../core/scrollbar/scrollbar.module';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';
import {SharedModule} from '../../../../core/shared/shared.module';



@NgModule({
  declarations: [TreatmentChatComponent],
    imports: [
        CommonModule,
        FormsModule,
        UtilsModule,
        ScrollbarModule,
        MatInputModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatListModule,
        MatRippleModule,
        MatMenuModule,
        MatButtonModule,
        FlexModule,
        FlexLayoutModule,
        TranslocoModule,
        MatDialogModule,
        MatCardModule,
        MatSelectModule,
        MatTableModule,
        SharedModule
    ],
  exports:[TreatmentChatComponent],
})
export class TreatmentChatModule { }
