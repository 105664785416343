import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService:AuthenticationService,
    private router:Router
  ){}

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): Promise<boolean> {
      if(this._authService.isLoggedIn()){
        //console.log(this._authService.getExpireTokenTime());
        if(this._authService.isTokenExpired()){
          return await this._authService.refreshTokenTrigger() ? true: this.router.navigate(["/"], {queryParams:{returnUrl:state.url}});
        }else{
          return true;
        }
      }else{
        this.router.navigate(["/"], {queryParams:{returnUrl:state.url}});
      }
  }
  
}
