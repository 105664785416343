import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class MessagingServiceService {
    private _message : Subject<string>  = new Subject<string>();
  
    constructor() { 
    }
  
    sendMessage(message: string)
    {
      this._message.next(message);
    }
  
    getMessage(): Observable<any> {
      return this._message.asObservable();
    }
  }