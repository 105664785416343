/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./route-handler.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./route-handler.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../modules/security/services/user.service";
var styles_RouteHandlerComponent = [i0.styles];
var RenderType_RouteHandlerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RouteHandlerComponent, data: {} });
export { RenderType_RouteHandlerComponent as RenderType_RouteHandlerComponent };
export function View_RouteHandlerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RouteHandlerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-route-handler", [], null, null, null, View_RouteHandlerComponent_0, RenderType_RouteHandlerComponent)), i1.ɵdid(1, 114688, null, 0, i3.RouteHandlerComponent, [i4.Store, i2.Router, i2.ActivatedRoute, i5.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RouteHandlerComponentNgFactory = i1.ɵccf("elastic-route-handler", i3.RouteHandlerComponent, View_RouteHandlerComponent_Host_0, {}, {}, []);
export { RouteHandlerComponentNgFactory as RouteHandlerComponentNgFactory };
