/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scrollbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scrollbar.component";
import * as i3 from "./scrollbar.service";
var styles_ScrollbarComponent = [i0.styles];
var RenderType_ScrollbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollbarComponent, data: {} });
export { RenderType_ScrollbarComponent as RenderType_ScrollbarComponent };
export function View_ScrollbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_ScrollbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-scrollbar", [], null, null, null, View_ScrollbarComponent_0, RenderType_ScrollbarComponent)), i1.ɵdid(1, 245760, null, 0, i2.ScrollbarComponent, [i3.ScrollbarService, i1.ElementRef, i1.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollbarComponentNgFactory = i1.ɵccf("elastic-scrollbar", i2.ScrollbarComponent, View_ScrollbarComponent_Host_0, { name: "name" }, {}, ["*"]);
export { ScrollbarComponentNgFactory as ScrollbarComponentNgFactory };
