import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { User } from 'src/app/modules/security/models/user.model';
import { UserService } from 'src/app/modules/security/services/user.service';
import { SidenavItem } from '../../../sidenav/sidenav-item/sidenav-item.model';

@Component({
  selector: 'elastic-toolbar-navigation-item',
  templateUrl: './toolbar-navigation-item.component.html',
  styleUrls: ['./toolbar-navigation-item.component.scss']
})
export class ToolbarNavigationItemComponent implements OnInit {
  nuvolaUser:User;

  @Input('item') item: SidenavItem;
  @Input('currentlyOpen') currentlyOpen: SidenavItem[] = [];
  
  @ViewChild('edtiCreateModal', {static:true}) edtiCreateModal:MatDialogRef<any>;

  constructor(
    private modalServide:MatDialog, 
    private userService:UserService,
  ) { }

  ngOnInit() {
    this.nuvolaUser = this.userService.readUserFromLocalStorage();
  }

  isDisabled(){
    if(!this.nuvolaUser.profileComplete){
      return true;
    } else {
      return false;
    }
  }

  openCreateModal(templateRef){
    this.edtiCreateModal = this.modalServide.open(templateRef, {panelClass:"lg-modal", closeOnNavigation:true});
  }

  createUpdateHandler(event){
    this.edtiCreateModal.close();
  }

  getCountForContent(item:SidenavItem){
    if(item == null || item == undefined){
      return 0;
    }

    if(!item.hasSubItems()){
      return item.getBadgeContentValue();
    }

    let sum = 0;

    item.subItems.forEach(val => {
      sum += this.getCountForContent(val);
    });

    return sum;
  }
}
