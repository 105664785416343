export enum CheckboxState {
    White = 'white',
    Red = 'red',
    Blue = 'blue',
    Green = 'green'
  }
  
export enum TypeIpr {
    None = 'N',
    Mesial = 'M',
    Distal = 'D'
  }