import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { TranslocoModule } from '@ngneat/transloco';
import { ToolbarModule } from '../../../core/toolbar/toolbar.module';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    ToolbarModule,
    MatIconModule
  ],
  declarations: [ChangePasswordComponent],
  exports:[ChangePasswordComponent]
})
export class ChangePasswordModule { }
