export const api = {
    //base:"https://nuvola40apidev.azurewebsites.net",
    //base:"https://nuvola40apiprod.azurewebsites.net",
    //base:"https://nuvola40apipre.azurewebsites.net",
    base:"https://nuvola40apiusaprod.azurewebsites.net",
    //base:"https://nuvola40apiusapre.azurewebsites.net",
    storageBase:"https://nuvolastorageusaprod.blob.core.windows.net",
    arcadLab: 'https://nuvola.arcadlab.com',
    isUsaVersion: true
}
