<div class="page-chat" fxLayout="column">

  <elastic-page-header [height]="'160px'" background="url(/assets/img/demo/headers/mac-2.png)" [reverse]="true"></elastic-page-header>

  <div class="container" fxFlex fxLayout="column">

    <elastic-breadcrumbs [currentPage]="'Chat'" [header]="'primary'"></elastic-breadcrumbs>

    <div class="card" fxFlex fxLayout="column">
      <mat-drawer-container fxFlex>

        <mat-drawer class="mat-elevation-z1" [mode]="drawerMode" position="start" align="start" [opened]="drawerOpen" [disableClose]="drawerDisableClose" #chatSidenav>
          <div class="sidenav-container" fxFlex fxLayout="column">
            <div class="chat-toolbar" fxLayout="row" fxLayoutAlign="start center">
              <img class="avatar pointer" src="assets/img/demo/avatars/noavatar.png">
              <span fxFlex><!-- fill space --></span>
              <button mat-icon-button [matMenuTriggerFor]="userMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>

            <elastic-scrollbar fxFlex fxLayout="column">
                <mat-nav-list class="chat-contacts">
                  <mat-list-item class="chat-contact" *ngFor="let chat of chats" (click)="setActiveChat(chat)" matRipple>
                    <img [src]="chat.picture" mat-list-avatar>
                    <h3 class="name" matLine>{{chat.name}}</h3>
                    <p class="last-message" matLine> {{chat.lastMessage}}</p>
                  </mat-list-item>
                </mat-nav-list>
            </elastic-scrollbar>
          </div>
        </mat-drawer>

        <div class="chat-inner-container" fxLayout="column">
          <div class="chat-toolbar" fxLayout="row" fxLayoutAlign="start center">

            <button type="button" mat-icon-button (click)="drawerOpen = !drawerOpen">
              <mat-icon>menu</mat-icon>
            </button>

            <div class="current-contact" fxLayout="row" fxLayoutAlign="start center">
              <img class="avatar pointer" [src]="activeChat.picture">

              <div fxLayout="column" fxLayoutAlign="center">
                <span class="name">{{ activeChat.name }}</span>
                <span class="status">Online</span>
              </div>
            </div>

            <span fxFlex><!-- fill space --></span>

            <button mat-icon-button>
              <mat-icon>attach_file</mat-icon>
            </button>

            <button mat-icon-button [matMenuTriggerFor]="chatMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <div class="chat-content" fxFlex fxLayout="column" fxLayoutAlign="start stretch">

            <elastic-scrollbar name="chat" #scroll fxFlex>
              <div class="messages-container">
                <div class="chat-message" *ngFor="let message of chats[0].messages" [ngSwitch]="message.who" fxLayout="column" fxLayoutAlign="end start">
                  <div class="partner" *ngSwitchCase="'partner'" fxFlexAlign="start" fxLayout="row" fxLayoutAlign="start start">
                    <img class="avatar" [src]="activeChat.picture">
                    <span class="message mat-elevation-z1">{{ message.message }}</span>
                  </div>
                  <div class="me" *ngSwitchCase="'me'" fxFlexAlign="end" fxLayout="row" fxLayoutAlign="end start">
                    <span class="message mat-elevation-z1">{{ message.message }}</span>
                    <img class="avatar" src="assets/img/demo/avatars/noavatar.png">
                  </div>
                </div>
              </div>
              <div #scrollToBottomElem></div>
            </elastic-scrollbar>


            <div class="chat-respond" fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field fxFlex>
                <input matInput (keyup.enter)="send()" [(ngModel)]="newMessage" placeholder="Type and hit enter to send your message">
              </mat-form-field>
              <button (click)="send()" mat-fab color="primary"><mat-icon>send</mat-icon></button>
            </div>
          </div>
        </div>

      </mat-drawer-container>

    </div>
  </div>

  <mat-menu #userMenu="matMenu">
    <button mat-menu-item>
      <mat-icon> account_circle </mat-icon>
      <span> Profile </span>
    </button>
    <button mat-menu-item>
      <mat-icon> settings </mat-icon>
      <span> Settings </span>
    </button>
    <button mat-menu-item>
      <mat-icon>help</mat-icon>
      <span>Help</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item>
      <mat-icon> exit_to_app </mat-icon>
      <span> Logout </span>
    </button>
  </mat-menu>

  <mat-menu #chatMenu="matMenu">
    <button mat-menu-item>
      <mat-icon> account_circle </mat-icon>
      <span> Contact Info </span>
    </button>
    <button mat-menu-item>
      <mat-icon> volume_mute </mat-icon>
      <span> Mute Chat </span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="clearMessages(activeChat)">
      <mat-icon> clear_all </mat-icon>
      <span> Clear Messages </span>
    </button>
  </mat-menu>
</div>

