import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorAddressGridComponent } from './doctor-address-grid.component';
import { GenericAutocompleteInputModule } from '../generic-autocomplete-input/generic-autocomplete-input.module';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatIconModule, MatInputModule, MatOptionModule, MatProgressSpinnerModule, MatSelectModule, MatSortModule, MatTableModule } from '@angular/material';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SharedModule } from '../../../core/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';



@NgModule({
  declarations: [DoctorAddressGridComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    SharedModule,
    MatCheckboxModule,
    MatCardModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogModule,
    GenericAutocompleteInputModule,
    NgxDatatableModule
  ],
  exports:[DoctorAddressGridComponent],
})
export class DoctorAddressGridModule { }
