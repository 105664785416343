<div fxLayout="column">

    <div fxFlex fxLayoutAlign="center center" fxLayout="column" *ngIf="isEditLoading">
        <mat-progress-spinner class="margin" [mode]="'indeterminate'" [color]="'accent'" [value]="10">
        </mat-progress-spinner>
    </div>

    <form fxLayout="column" [formGroup]="photoForm" (ngSubmit)="save()" *ngIf="!isEditLoading">
        <mat-card fxFlex="100%" fxFlex.gt-sm fxLayout="column">
            <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <h3>{{'toolbar.USERCOMPONENT.profilePhoto' | transloco}}</h3>
                </div>
                <button type="reset" mat-icon-button align="end" (click)="closeModal()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div *ngIf="!currentUser.photoFileName && !photoForm.controls.file.value">
                <!-- Drag and Drop  -->
                <drag-and-drop-file (fileDropped)="fileDroppedHandle($event)" [fileName]="'user_'+currentUser.idAdmin"
                    [maxFileSize]="1000000">

                </drag-and-drop-file>
            </div>

            <div fxLayout.gt-sm="row" fxLayoutAlign="center center"
                *ngIf="currentUser.photoFileName || (photoForm.controls.file.value && photoForm.controls.file.value.fileName)">
                <img mat-card-avatar *ngIf="currentUser.photoFileName" [src]="currentUser.photoFullUrl" alt="">
                <img mat-card-avatar *ngIf="!currentUser.photoFileName && photoForm.controls.file.value.fileName"
                    [src]="photoForm.controls.file.value.photoUrl" alt="">
            </div>
        </mat-card>
    </form>
    <div fxLaout='row' fxLayoutAlign="space-between center">

        <div fxFlex fxLayout='row' fxLayoutAlign="space-between" *ngIf="!isEditLoading" style="padding-top: 7px;">

            <div fxFlex fxLayout='row'>
                <button mat-raised-button color="warn" (click)="delete()"
                    *ngIf="currentUser.photoFileName || (photoForm.controls.file.value && photoForm.controls.file.value.fileName)">
                    {{'COMMON.DeleteImageButton' | transloco}}
                </button>
            </div>

            <div fxFlex fxLayout='row' fxLayoutAlign="end end" fxLayoutGap="10px">
                <button mat-raised-button class="mat-primary" (click)="closeModal()" fxLayoutAlign="end">
                    {{'COMMON.CloseButton' | transloco}}
                </button>
                <button mat-raised-button class="btn-default" (click)="save()"
                    [disabled]="!photoForm.controls.file.value || !photoForm.controls.file.value.fileName"
                    *ngIf="!currentUser.photoFileName" fxLayoutAlign="end">
                    {{'COMMON.SaveButton' | transloco}}
                </button>
            </div>


        </div>
    </div>
</div>