export class Cart {
  productImage_EN: string;
  productImage_IT: string;
  productCode: string;
  productTitle_EN: string;
  productTitle_IT: string;
  productDescription_EN: string;
  productDescription_IT: string;
  availability: boolean;
  price: number;
  productId: number;
  count: number;
  total: number;
  pdfLink_EN: string;
  pdfLink_IT: string;
  videoLink_EN: string;
  videoLink_IT: string;

  constructor(model: any = null) {
    if (model) {
      this.productImage_EN = model.productImage_EN;
      this.productImage_IT = model.productImage_IT;
      this.productTitle_EN = model.productTitle_EN;
      this.productTitle_IT = model.productTitle_IT;
      this.productCode = model.productCode;
      this.productDescription_EN = model.productDescription_EN;
      this.productDescription_IT = model.productDescription_IT;
      this.availability = model.availability ;
      this.price = model.price;
      this.count = model.count;
      this.productId = model.productId;
      this.total = model.total;
      this.pdfLink_EN = model.pdfLink_EN;
      this.pdfLink_IT = model.pdfLink_IT;
      this.videoLink_EN = model.videoLink_EN;
      this.videoLink_IT = model.videoLink_IT;
    }
  }
}
