/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./core/route-handler/route-handler.component.ngfactory";
import * as i3 from "./core/route-handler/route-handler.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
import * as i6 from "./modules/security/services/user.service";
import * as i7 from "./app.component";
import * as i8 from "@ngneat/transloco";
import * as i9 from "@angular/platform-browser";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-route-handler", [], null, null, null, i2.View_RouteHandlerComponent_0, i2.RenderType_RouteHandlerComponent)), i1.ɵdid(1, 114688, null, 0, i3.RouteHandlerComponent, [i4.Store, i5.Router, i5.ActivatedRoute, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.TranslocoService, i9.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("elastic-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
